import React from 'react'
import styled from 'styled-components'
import {ArrowDropDown} from '@styled-icons/material/ArrowDropDown'
import {ArrowDropUp} from '@styled-icons/material/ArrowDropUp'
import { useState } from 'react'
import { Table } from '../../reusable/Table/Table'
import {b} from "../../styles/variables";
function UserOrders({userOrders,index}) {
// const [dataVisible, setDataVisible] = useState(false)

  return (
    <DataWrapper >     
        <DataCard >        
         <FlexWrapper1><h6>{index+1}. </h6></FlexWrapper1>
          <FlexWrapper>
            <h6>{userOrders?.user?.user_name}</h6>
          {/* <ArrowIconContainer>
           {!dataVisible?<ArrowDown onClick={()=>setDataVisible(true)}/>:<ArrowUp onClick={()=>setDataVisible(false)}/>}
           </ArrowIconContainer> */}
        </FlexWrapper>
        <FlexWrapper><h6>{userOrders?.user?.fs_id }</h6></FlexWrapper>
        </DataCard> 
        <OrderList >
           <Table>
            <Table.Head>
                <Table.TR>
            <Table.TH>
                Sr. No.
            </Table.TH>
            <Table.TH>
                Food Item
            </Table.TH>
            <Table.TH>
                Qty
            </Table.TH>
            <Table.TH>
               Vendor
            </Table.TH>
            </Table.TR>
            </Table.Head>
            <Table.Body>


            {userOrders?.orderlists.map((orderlist,index)=>{
              return  (<React.Fragment key={index}>
               <Table.TR>

                  <Table.TD>
                    {index+1}
                  </Table.TD>

                  <Table.TD>
                    {orderlist.food.food_name}
                  </Table.TD>

                  <Table.TD>
                  {orderlist.quantity}
                  </Table.TD>
                  <Table.TD>
                  {orderlist.food.vendor.vendor_name}
                  </Table.TD>
                  </Table.TR>
                </React.Fragment>)
            })}
        
        </Table.Body>

           </Table> 
        </OrderList>       
              
        </DataWrapper>
  )
}

export default UserOrders

const DataCard = styled.div`
display:flex;
width: 100%;
padding: 1rem 0;
color: #4f4c4c;
font-weight: 100;
&:hover{
  background-color:#f9f6ee;

}

`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex:1;
  justify-content:center;
  h6{
    font-weight: 00;
  } 
  @media ${b.mobile}{  
  h6{
    font-size: 1.3rem;
  }   
}`
const FlexWrapper1 = styled.div`
  display: flex;
  flex-direction: row;
  flex:0.3;
  justify-content:left;
  @media ${b.mobile}{  
  h6{
    font-size: 1.3rem;
  }   
}
`
// const ArrowUp = styled(ArrowDropUp)`
//   size:13;
//   &:hover{
//     cursor: pointer;
//   }

// `
// const ArrowDown = styled(ArrowDropDown)`
//   size:13;
//   &:hover{
//     cursor: pointer;
//   }

// `
const OrderList= styled.div`
display: flex;
flex-direction: row;
width: 100%;
justify-content: center;
/* display: ${({dataOn})=>dataOn? "flex" : "none"}; */
`;

const DataWrapper = styled.div`
display: flex;
flex-direction: column;
`
const ArrowIconContainer = styled.div`
margin-left : 2%;
width:20px;
height: auto;
`



import { createSlice } from "@reduxjs/toolkit";

const vendorSlice = createSlice({
    name: "vendors",
    initialState: { 
      vendorList:[],            
      fetchingData:false
    },
    reducers:{
        getVendors: (state,action)=>{ 
            state.fetchingData=true
            
        },       

        setVendors: (state,action)=>{
            state.fetchingData=false
            state.vendorList=action.payload
        },      
       clearVendorList:(state)=>{
        state.vendorList=[]
       },


    }
})

export default vendorSlice.reducer;
export const { getVendors,setVendors,clearVendorList} = vendorSlice.actions 
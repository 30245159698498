import React from "react";
import {Home} from '@styled-icons/boxicons-regular/Home';
import {FoodMenu as Menu} from '@styled-icons/boxicons-solid/FoodMenu';
import {FastFood as Cart} from '@styled-icons/ionicons-outline/FastFood';
import {ListStars as Orders} from '@styled-icons/bootstrap/ListStars';
import {Bill as Finance} from '@styled-icons/remix-line/Bill';
import {FoodBank as Restos} from '@styled-icons/material-rounded/FoodBank';
import {CardChecklist as MyOrder} from '@styled-icons/bootstrap/CardChecklist';
import {UserLocation} from '@styled-icons/remix-line/UserLocation';
import {UserDetail} from '@styled-icons/boxicons-solid/UserDetail'
export const MyData = [
  {
    title: "Home",
    path: "/",
    icon: <Home size={18}/>   
  },
  {
    title: "Cart",
    path: "/cart",
    icon: <Cart size={18}/>  
  },


  {
    title: "Restaurants",
    path: "/vendors",
    icon: <Restos size={18}/>    
  },
  {
    title: "Food",
    path: "/fooddata/foodall",
    icon: <Menu size={18}/>
       
  },

  {
    title: "My Orders",
    path: "/myorders",
    icon: <MyOrder size={18}/>    
  },

  {
    title: "My Preferred Location",
    path: "/mylocation",
    icon: <UserLocation size={18}/>    
  },
];

export const AdminData = [
  {
    title: "As per Vendor",
    path: "liveorders/byvendor",
    icon: <Orders size={18}/>    
  },
 
  {

    title: "As per User",
    path: "liveorders/byuser",
    icon: <UserDetail size={18}/>    
  },

 
  
];

export const AccountData = [

  {
    title: "Billing",
    path: "analysis/financebydate",
    icon: <Finance size={18}/>
    
  },
  
];
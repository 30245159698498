import {configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../saga/rootSaga";
import rootReducer from "./feature/rootReducer";

const sagaMiddleware=createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware:(getDefaultMiddleware)=> getDefaultMiddleware().concat(sagaMiddleware)

});

sagaMiddleware.run(rootSaga);

export default store;


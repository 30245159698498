import React from 'react'
import styled from 'styled-components'
import {b} from '../../styles/variables'
import {Table} from '../../reusable/Table/Table'
import { FlxCenter, FlxColumn} from '../../styles/globalStyles'
import { useDispatch, useSelector } from 'react-redux'
import { Link ,useNavigate} from 'react-router-dom'
import { addToCart,decreaseCart,removeFromCart, getTotals, clearCart, updateCartCredit  } from '../../redux/feature/cartSlice'
import { useEffect } from 'react'
import {Rupee} from '@styled-icons/boxicons-regular/Rupee'
import { placeOrder} from '../../redux/feature/orderSlice'
import { toast } from 'react-hot-toast'
import { useState } from 'react'
import { ROLES } from '../../App';
import { AVAILS } from '../MenuCard'
import { HeaderContainer,DescriptionContainer,VendorDetails,FoodSource,AvailabilityWrapper,AvailabilityContainer,HWrapper} from '../MenuCard'
import { TextContainer } from '../../styles/globalStyles'
import {FoodTag} from '@styled-icons/boxicons-regular/FoodTag'

function Cart() { 
  const navigate= useNavigate()
  const cart = useSelector((state)=>state.cart)
  const auth = useSelector((state)=>state.auth)  
  const dispatch=useDispatch()  
  const [time, setTime] = useState(new Date());
  const [isAdmin,setIsAdmin]=useState(false)  
  const [isDisabled, SetIsDisabled] = useState(false)
  const bcutoff='0945';
  const lcutoff='1200';
  const scutoff='1600';

useEffect(() => {  
    dispatch(getTotals(auth.creds.usercredit));
    const setRole=()=>{
      if (auth.creds.roleslist.includes(ROLES['Admin'])){
        setIsAdmin(true)
      }
      else {
        setIsAdmin(false)
      }    
    }
    setRole()
    
  }, [cart]);  

useEffect(() => {
  const interval = setInterval(() => setTime(new Date()), 1000);
  return () => {
    clearInterval(interval);
  };
}, []);   

const formatedTime=time.toLocaleTimeString('it-IT',{ hour12:false, hour: '2-digit', minute: '2-digit' }).replace(/:/g, "")
//console.log(typeof isAdmin)
//console.log(time<bcutoff)
  const handleAddToCart = (food,type) => {
    dispatch(addToCart([food,type]));    
  };
  const handleDecreaseCart = (food,type) => {
    dispatch(decreaseCart([food,type]));
  };
  const handleRemoveFromCart = (food) => {
    dispatch(removeFromCart([food]));
  };
  const handleClearCart = () => {
    dispatch(clearCart());
    toast.success('Cart Cleared',
    {
      position: 'bottom-right',
      duration: 5000,  
  })
  };

  const handlePlaceOrder = (cartItems,time,bcutoff,lcutoff,scutoff,isAdmin) => { 
    
    let status=""
    const orderList={BREAKFAST:[],LUNCH:[],SNACK:[]}  
    cartItems.map((cartItem,index)=>{
      if (cartItem.cartQuantity[0]>0){
        if (time<bcutoff || isAdmin)
        {
        //console.log(index + "inside B")
        orderList.BREAKFAST.push(cartItem)
        orderList.BREAKFAST[orderList.BREAKFAST.length-1] = {
        ...orderList.BREAKFAST[orderList.BREAKFAST.length-1],
        cartQuantity: orderList.BREAKFAST[orderList.BREAKFAST.length-1].cartQuantity[0]  }         
        }
        else{
          toast.error("Coudln't Place Order, Please make Breakfast Qty as zero")
          status="stoped"
          return status;
        }              
      }
       if (cartItem.cartQuantity[1]>0){
        if (time<lcutoff || isAdmin)
        {
        //console.log(index + "inside L")
        orderList.LUNCH.push(cartItem)
        orderList.LUNCH[orderList.LUNCH.length-1] = {
          ...orderList.LUNCH[orderList.LUNCH.length-1],
          cartQuantity: orderList.LUNCH[orderList.LUNCH.length-1].cartQuantity[1]
        }}
        else{
          toast.error("Coudln't Place Order, Please make Lunch Qty as zero")
          status="stoped"
          return status;
        }  
      
      }
        if (cartItem.cartQuantity[2]>0){
          if (time<scutoff || isAdmin)
        {
          //console.log(index + "inside S")
          orderList.SNACK.push(cartItem)
          //console.log(orderList)
          orderList.SNACK[orderList.SNACK.length-1] = {
            ...orderList.SNACK[orderList.SNACK.length-1],
            cartQuantity: orderList.SNACK[orderList.SNACK.length-1].cartQuantity[2]
          }  
          } 
          else{
            toast.error("Coudln't Place Order, Please make Snacks Qty as zero")
            status="stoped"
            return status;
          }      

      }
    })
    //console.log(orderList)
    if (status==="stoped"){
      return
    }
    else{
    dispatch(placeOrder([orderList]));    
    navigate('/')
    }

  };

  
//console.log(cart.cartItems[2])
  return (
<MainContainer>   
 
<ContentWrapper>{
  cart?.cartItems.length===0?
  <NoItemContainer>
    <p fs={"2rem"} fw={500} fc={"#180270"} >Your Cart is Empty. Please add something to eat....</p>
    <Link to='/fooddata/foodall'>
    <ContinueButton>Order Something</ContinueButton>
    </Link> 
  </NoItemContainer> :
 
<>
<Titles>
  <Wrapper1><p fs={"2rem"}  fc ={"#180270"} fw={700} >Food Description</p></Wrapper1>
 <Wrapper2> <p fs={"2rem"}  fc ={"#180270"} fw={700}>Price</p></Wrapper2>
 <Wrapper2> <p fs={"2rem"}  fc ={"#180270"} fw={700}>Quantity</p></Wrapper2>
 <Wrapper3><p fs={"2rem"} fc ={"#180270"} fw={700}>Total</p></Wrapper3>
</Titles>
<CartBodyContainer>
{cart?.cartItems && cart.cartItems.map((cartItem)=>(
<CardContainer key = {cartItem?.food_id}>
<DescContainer>

<HeaderContainer>
    <TextContainer fc={"#180270"} fs={"1.4rem"} fw = {500} ><b>{cartItem.food_name}</b></TextContainer>
    {cartItem.food_description && <><DescriptionContainer><TextContainer fc={"#301c80"} fs={"1rem"} hfc={"white"} fw = {200}><b></b> {cartItem.food_description}<b></b></TextContainer></DescriptionContainer> </> }    
</HeaderContainer>
<VendorDetails><p><b>Resto Name: </b></p> <p> {cartItem.vendor.vendor_name}</p></VendorDetails>
<FoodSource>
<FoodTag size={20} color={cartItem.food_source==="VEG" ? "green" : "#d40000"}/>
</FoodSource>
<>
{cartItem.availabilities.length !==0 && <AvailabilityWrapper>
  <HWrapper><h6><b>Available For: </b> </h6></HWrapper>
<AvailabilityContainer>
  {cartItem.availabilities?.map((item,index)=>{  
  return <p key={index}>{AVAILS[item]}</p>
})   
  }
</AvailabilityContainer>

</AvailabilityWrapper>}
</>
<RemoveContainer><RemoveLink onClick={()=>{handleRemoveFromCart(cartItem.food_id)}}>Remove from cart</RemoveLink></RemoveContainer>
</DescContainer>
<PriceContainer fs={"1.8rem"} fc ={"#180270"} fw={200}>{cartItem?.food_price}</PriceContainer>

<Wrapper2>

<AllThreeTimes>
<BQtyContainerWrapper >
  <BH>Breakfast</BH>
  
  <BQtyContainer time={formatedTime} bcutoff={bcutoff} role={!isAdmin}>
  <BPlusMinusButton time={formatedTime} bcutoff={bcutoff} role={!isAdmin} disabled = {cartItem?.cartQuantity[0]>0 ? false : true} onClick={() => handleDecreaseCart(cartItem,"B")}>-</BPlusMinusButton>
  <p fs={"1.7rem"} ai= {"center"} pl={"7px"} fc ={"#180270"} fw={700}>{cartItem?.cartQuantity[0]}</p>
  
  <BPlusMinusButton time={formatedTime} bcutoff={bcutoff} role={!isAdmin} disabled = {formatedTime<bcutoff || isAdmin? false : true} onClick={() => handleAddToCart(cartItem,"B")}>+</BPlusMinusButton>
  </BQtyContainer>
  </BQtyContainerWrapper>

  <LQtyContainerWrapper>
  <LH>Lunch</LH>
    <LQtyContainer time={formatedTime} lcutoff={lcutoff} role={!isAdmin} >
    <LPlusMinusButton time={formatedTime} lcutoff={lcutoff} role={!isAdmin} disabled = {cartItem.cartQuantity[1]>0 ? false : true} onClick={() => handleDecreaseCart(cartItem,"L")}>-</LPlusMinusButton>
  
  <p fs={"1.7rem"} ai= {"center"} pl={"7px"} fc ={"#180270"} fw={700}>{cartItem.cartQuantity[1]}</p>
  <LPlusMinusButton time={formatedTime} lcutoff={lcutoff} role={!isAdmin} disabled = {formatedTime<lcutoff || isAdmin ? false : true} onClick={() => handleAddToCart(cartItem,"L")}>+</LPlusMinusButton>
  </LQtyContainer></LQtyContainerWrapper>
  <SQtyContainerWrapper>

  <SH>Snacks</SH>
    <SQtyContainer time={formatedTime} scutoff={scutoff} role={!isAdmin}>
    <SPlusMinusButton time={formatedTime} scutoff={scutoff} role={!isAdmin} disabled = {cartItem.cartQuantity[2]>0 ? false : true} onClick={() => handleDecreaseCart(cartItem,"S")}>-</SPlusMinusButton>
  <p fs={"1.7rem"} ai= {"center"} pl={"7px"} fc ={"#180270"} fw={700}>{cartItem.cartQuantity[2]}</p>
  
  <SPlusMinusButton time={formatedTime} scutoff={scutoff} role={!isAdmin} disabled = {formatedTime<scutoff || isAdmin ? false : true} onClick={() => handleAddToCart(cartItem,"S")}>+</SPlusMinusButton>
  </SQtyContainer></SQtyContainerWrapper>
  </AllThreeTimes></Wrapper2>


  <Wrapper3><TotalContainer><RupeeIcon color={"#180270"}/><p fs={"1.8rem"} fc ={"#180270"} pl={"0.1rem"} fw={200}>{cartItem.itemTotal}</p></TotalContainer></Wrapper3>


</CardContainer>

))


}
</CartBodyContainer>
<FooterContainer> 
  
  <FooterWrapper>
  <SumContainer>
    <TotalLine1><p fs={"1.8rem"} fc={"#180270"} fw={700}><b>Total:</b> {cart.cartTotalAmount}</p></TotalLine1>
    
  </SumContainer>
  <TotalButtonContainer>
<OrderMoreButton  onClick={() =>navigate(-1)}>Order More</OrderMoreButton>
<ClearCartButton  onClick={() => handleClearCart()}>Clear Cart</ClearCartButton>

<PlaceOrderButton disabled = {cart.cartTotalQuantity > 0 ? false : true} onClick={() => handlePlaceOrder(cart.cartItems,formatedTime,bcutoff,lcutoff,scutoff,isAdmin)}>Place Order</PlaceOrderButton>
  
  </TotalButtonContainer>
  </FooterWrapper>
  
  </FooterContainer> 
</>

}

</ContentWrapper>
 
</MainContainer>
  )
}

export default Cart;

const MainContainer = styled.div`
width: 100%;
height:100%;
font-family: nunito sans-serif;
color:  #180270;
display: flex;
justify-content: center;
@media ${b.mobile}{
  font-size: 70%;
}
`;

/* const HeaderContainer= styled.div`
margin: 0 20px;
display: flex;
width:100%;
flex-direction: row;
padding: 20px 30px;
border-bottom: 1px solid gray;
justify-content:space-between;
@media ${b.mobile}{
${FlxColumn}
justify-content: center;
align-items: center;
}
` */
const OrderMoreButton = styled.button`
font-size: medium;
height: 4rem;
width: 15rem;
border-radius: 20px;
border: 2px solid #F7707D;
margin-bottom: 1rem;
cursor: pointer;
&:hover{
background-image:linear-gradient(90deg, #F7707D 0%, #F29173 100%);
color:white;
border: none;
}
&:disabled{
  background-color: #ece9e9;
  border: none;
  &:hover{
    background-image:none; 
    color: #180270;
  }
 
}

@media ${b.mobile}{
margin-top: 10px;
width: auto;
padding: 0.2rem 0.5rem;
}
`
const ClearCartButton=styled(OrderMoreButton)`
`
const ContentWrapper = styled.div`
display: flex;
flex-direction: column;
height: 100%;
width: 65%;
background-color:white;
align-items: center;
padding: 0 2rem;
 
@media ${b.mobile}{
  width: 100%; 
  padding: 1rem 2rem;
}


`
const NoItemContainer = styled.div`

display: flex;
flex-direction: column;
height: 100%;
${FlxCenter}
`;

const Titles = styled.div`
display: flex;
width: 100%;
height: 5%;
flex-direction: row;
/* padding-bottom: 20px; */
align-items: center;
border-bottom: 1px solid gray;
font-size: 1.8rem;
font-weight:500;
@media ${b.rare}{
 font-size: 1.2rem
}

`
const CartBodyContainer = styled.div`
width: 100%;
height: 65%;
overflow-y: auto;
`
const CardContainer = styled.div`
display:flex;
flex-direction:row;
width: 100%;
padding:20px 0;
align-items: center;

&:hover{
  /* transform: translate(0, -5px); */
  cursor: pointer;
  /* box-shadow:  0 0 0 0px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%); */
  box-shadow: rgba(28,28,28,0.12) 0px 0.4rem 1.8rem;

}

`

const ContinueButton = styled(OrderMoreButton)`
 margin-top : 20px;

`

const DescContainer = styled.div`
 flex:2;
 display: flex;
 flex-direction: column;
 width:100%;
 align-items:left;
 line-height: 2.5rem;
/* border-right: 1px solid gray; */

 
`
const PriceContainer = styled.div`
 flex:1;
 justify-content: left;
 min-height: 100%;
 @media ${b.mobile}{
  justify-content: center; 
 font-size: 1.6rem;
}
`
const QtyContainerWrapper = styled.div`
  flex:1;
`
const TotalContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
@media ${b.mobile}{
 font-size: 2rem;
}

`;
const Wrapper1 = styled.div`
display: flex;
flex:2;
`;
const Wrapper2 = styled.div`
display: flex;
flex:1;


`;
const Wrapper3 = styled.div`
display: flex;
flex:1;
justify-content: center;
`;

const BQtyContainer=styled.div`
display: flex;
flex-direction: row;
border: 1px solid gray;
min-width:80px;
height: 40px;
border-radius: 5px;
justify-content: center;
align-items: center;
background-color:${({time,bcutoff,role})=>(time > bcutoff && role ? "#AAAFB4" : "none")};
p{
  padding: 0 1px;
}
  @media ${b.mobile}{
  p{
    font-size: 1.8rem;
  }
@media ${b.rare}{
 min-width:60px;
}

}

`
const BH=styled.h5`
width: 100%;


`
const LH=styled.h5`

  padding-right: 5%;

`
const SH=styled.h5`
padding-right: 5%;
  

`
const LQtyContainer=styled.div`
display: flex;
flex-direction: row;
border: 1px solid gray;
min-width:80px;
height: 40px;
border-radius: 5px;
justify-content: center;
align-items: center;
background-color:${({time,lcutoff,role})=>time>lcutoff && role ?"#AAAFB4":"none"};
&p{
  padding: 0 1px;
}
  @media ${b.mobile}{
  p{
    font-size: 1.8rem;
  }
}
  @media ${b.rare}{
 min-width:60px;
}

`
const SQtyContainer=styled.div`
display: flex;
flex-direction: row;
border: 1px solid gray;
min-width:80px;
height: 40px;
border-radius: 5px;
justify-content: center;
align-items: center;
background-color:${({time,scutoff,role})=>time>scutoff && role?"#AAAFB4":"none"};

&p{
  padding: 0 1px;
}
  @media ${b.mobile}{
  p{
    font-size: 1.8rem;
  }
}
@media ${b.rare}{
 min-width:60px;
}

`

const BPlusMinusButton = styled.button`
display: flex;
flex:1;
justify-content:center;
align-items: center;
border-radius:inherit;
border: none;
cursor: pointer;
font-size:1.8rem;
height: 100%;
width: 100%;
&:hover {
    background-color: ${({time,bcutoff,role})=>time>bcutoff && role ?"#AAAFB4":"#e7caca"};
    color:${({time,bcutoff,role})=>time>bcutoff && role ?"#180270":"white"};
  }
`
const LPlusMinusButton = styled(BPlusMinusButton)`
&:hover {
  background-color: ${({time,lcutoff,role})=>time>lcutoff && role ?"#AAAFB4":"#e7caca"};
    color:${({time,lcutoff,role})=>time>lcutoff && role ?"#180270":"white"};
  }
`
const SPlusMinusButton = styled(BPlusMinusButton)`
&:hover {
  background-color: ${({time,scutoff,role})=>time>scutoff && role ?"#AAAFB4":"#e7caca"};
    color:${({time,scutoff,role})=>time>scutoff && role ?"#180270":"white"};
  }
`
const RemoveContainer = styled.div`
display: flex;
justify-content: left;
width: 100%;
`

const RemoveLink = styled(Link)`
font-size: 1.3rem;
text-decoration: none;
color: #ee4902;
&:hover{
  text-decoration: underline;
}
`

const FooterContainer = styled.div`
display:flex;
height: 15%;
width: 100%;

`
const FooterWrapper = styled.div`
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
align-items: center;


`

const SumContainer = styled.div`
display: flex;
width: 100%;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 1rem 0;


`
const TotalButtonContainer = styled.div`
display: flex;
flex-direction:row;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 0 3rem;
@media ${b.mobile}{
  width: 100%; 
  padding: 0 0;
}
`
const TotalLine1=styled.div`
display:flex;
flex-direction: row;
justify-content:space-between;
@media ${b.mobile}{
  font-size: 2.2rem;
}

`
const TotalLine2 = styled.div`
display:flex;
flex-direction: row;
justify-content:space-between;
border-bottom: 1px solid gray;

`
const TotalLine3 = styled.div`
display:flex;
flex-direction: row;
justify-content:space-between;

`
const PlaceOrderButton = styled(OrderMoreButton)`

`

const AllThreeTimes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;

`
const BQtyContainerWrapper = styled.div`
display: flex;
flex-direction: row;
padding: 0.5rem 0;
justify-content: space-between;
align-items: center;
width:100%;

`
const LQtyContainerWrapper = styled.div`
display: flex;
flex-direction: row;
padding: 0.5rem 0;
justify-content: space-between;
align-items: center;
width:100%;
`

const SQtyContainerWrapper = styled.div`
display: flex;
flex-direction: row;
padding: 0.5rem 0;
justify-content: space-between;
align-items: center;
width:100%;

`
const RupeeIcon = styled(Rupee)`
height:2.5rem;
align-self: center;
@media ${b.mobile}{
  height:1.5rem;
}
`
import React from 'react'
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { cancelItem, getMyOrder,cancelOrder} from '../../redux/feature/orderSlice';
import { useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';
import {b} from "../../styles/variables";
import {NavLink} from 'react-router-dom';
import Loader from '../Loader';
import {Cross as Cancel } from '@styled-icons/entypo/Cross'
import { useNavigate } from 'react-router-dom';
import { updateCartCredit } from '../../redux/feature/cartSlice';
//import { confirmAlert } from 'react-confirm-alert';
import { getUserInfo } from '../../redux/feature/authSlice';

function MyOrders() {
  const myUserId = useSelector((state)=>state.auth.creds.user_id)
  const navigate = useNavigate()
  useEffect(() => {    
    dispatch(getMyOrder(myUserId))
   // dispatch(getUserInfo())     
    },[]);
const order = useSelector((state)=>state.order)
const cart = useSelector((state)=>state.cart)  
const myOrder=order.myOrder
  //const loading = order.isLoading
  const dispatch = useDispatch()
  //console.log(myOrder)
  // const [byUserResult,setByUserResult] = useState([])
  // const [byVendorResult,setByVendorResult] = useState([])

   
  const [orderType,SetOrderType] = useState(!localStorage.getItem("currentMyOrderPage")
                                            ?"Breakfast" : localStorage.getItem("currentMyOrderPage")
                                          )
  const [B,setB]=useState(localStorage.getItem("currentMyOrderPage") ? (localStorage.getItem("currentMyOrderPage") ==="Breakfast" ? 1 : 0):1)
  const [L,setL] = useState(localStorage.getItem("currentMyOrderPage") ==="Lunch" ? 1 : 0)
  const [S,setS] = useState(localStorage.getItem("currentMyOrderPage") ==="Snack" ? 1 : 0)
  const orderData = orderType=== "Breakfast" ? myOrder.BREAKFAST :
                    orderType=== "Lunch" ? myOrder.LUNCH :
                    myOrder.SNACK
//console.log(orderData)
//console.log(myUserId)



const handleClickB =(type)=>{
  setB(1)
  setL(0)
  setS(0)
  localStorage.setItem("currentMyOrderPage",type)
  SetOrderType(type)
}
const handleClickL =(type)=>{
  setB(0)
  setS(0)
  setL(1)
  localStorage.setItem("currentMyOrderPage",type)
  SetOrderType(type)
}
const handleClickS =(type)=>{
  setB(0)
  setS(1)
  setL(0)
  localStorage.setItem("currentMyOrderPage",type)
  SetOrderType(type)
}
const handleCancelOrder=(orderId)=>{
  //console.log(orderId)
  dispatch(cancelOrder(orderId))
  //navigate(0)
}
const handleCancelItem = (item_id,food_name) => {
console.log(item_id)
//   if (window.confirm(`Remove ${food_name} from My Orders`))
// {  
  dispatch(cancelItem([item_id]))
    
// }
//navigate(0) 
}

  return (
    <>
    <MainContainer>
      <HeaderWrapper>
      <HeaderContainer>
        <CustomNavlink livestate={B} onClick={()=>handleClickB("Breakfast")}>Breakfast</CustomNavlink>
        <CustomNavlink livestate={L} onClick={()=>handleClickL("Lunch")}>Lunch</CustomNavlink>
        <CustomNavlink livestate={S} onClick={()=>handleClickS("Snack")}>Snacks</CustomNavlink>
      </HeaderContainer>
      </HeaderWrapper>
      <WrapperContainer>
      {order.isLoading ? <Loader/> : 
      <ByUserContainer>{Object.keys(orderData)?.length === 0? <React.Fragment>You have not placed any order for {orderType}</React.Fragment> :
      (<>
       <MiniHeader><p>My Orders</p>
       <TokenContainer><b>Order Token: </b>{orderData?.order_id} </TokenContainer>
      </MiniHeader>
      <SubHeader>
        <FlexWrapper><h3>Name</h3></FlexWrapper>
        <FlexWrapper><h3>Vendor</h3></FlexWrapper>
        <FlexWrapper1><h3>Qty</h3></FlexWrapper1>
        <FlexWrapper1><h3>Amount</h3></FlexWrapper1>
        <FlexWrapper1><h3></h3></FlexWrapper1>
      </SubHeader>
      <SubBody>{
        orderData?.orderlists?.map((item,index)=>(
        
          <DataCard key={index}>
           <FlexWrapper><h4> {item?.food?.food_name}</h4></FlexWrapper>
            <FlexWrapper><h4>{item?.food?.vendor?.vendor_name}</h4></FlexWrapper>
            <FlexWrapper1><h4>{item?.quantity}</h4></FlexWrapper1>
            <FlexWrapper1><h4>{item?.food_prices}</h4></FlexWrapper1>
            <FlexWrapper1><CancelIcon size= {25} onClick={()=>handleCancelItem(item.id,item?.food?.food_name,myUserId)}/></FlexWrapper1>
       
          </DataCard>
          
        ))}     

      </SubBody>
      <SubFooter>
      
      <CancelOrderButton onClick={()=>handleCancelOrder(orderData.order_id)}>Cancel Order</CancelOrderButton>

      </SubFooter> </>) }
      </ByUserContainer>    
      }
      </WrapperContainer>
    </MainContainer>
    
    </>
  )
}

export default MyOrders;

const MainContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
font-family: nunito sans-serif;
`
const WrapperContainer = styled.div`
display: flex;
height: 100%;
flex-direction: row;
justify-content: center;
align-items:center;
padding-top: 1%;
padding-bottom: 9%;
@media ${b.mobile}{
 padding: 0.5rem 0;
}
`;
const HeaderWrapper = styled.div`
display: flex;
flex-direction: row;
width: 100%;
background: white;
justify-content: space-between;
align-items: center;
padding: 2rem 2rem;
height: 70px;
@media ${b.mobile}{ 
  font-size:1.2rem;  
  height: auto;
  padding: 1rem 1rem;
}

`;

const HeaderContainer = styled.div`
display: flex;
flex-direction: row;
justify-content:left;
margin-bottom: 0.5rem;
`;


const ByUserContainer = styled.div`
display: flex;
flex-direction: column;
background-color: white;
height: 100%;
width:49%;
align-items: center;
color: black;
padding: 2rem 2rem;
@media ${b.mobile}{  
  width: 100%;
  padding: 0 1.2rem;
}

`;
const ByVendorContainer = styled.div`
display: flex;
flex-direction: column;
background-color: white;
min-height: 95%;
padding: 2rem 0;
width:45%;
overflow-x: hidden;
align-items: center;

&:hover{
  overflow-y:auto;

}
`;
const TokenContainer = styled.div`

font-weight: 500;
`
const CustomNavlink = styled(NavLink)`
text-decoration: none;
color: #393838;
font-size:${({livestate})=>livestate===1 ? "2.3rem" : "2.1rem"};
font-weight:600;
border-bottom:${({livestate})=>livestate===1 ? "5px solid green" : "0px"} ;
padding: 1rem 0;
margin-right: 3rem;
&:hover{
cursor: pointer;
}
@media ${b.mobile}{  
  font-size:${({livestate})=>livestate===1 ? "1.7rem" : "1.5rem"};
  padding: 0.3rem 0; 
  margin-right: 1.2rem;  
}
`;

const SubHeader = styled.div`
width: 100%;
padding: 0 0;
font-weight: 600;
display: flex;
background-color:#d9f8ec;
justify-content: left;
@media ${b.mobile}{  
  font-size:1.5rem;   
}

`
const SubBody = styled.div`
width: 100%;
max-height: 70%;
padding-bottom: 2rem;
overflow-y: auto;
@media ${b.mobile}{  
  padding-bottom: 2rem;   
}
`
const SubFooter = styled.div`
display: flex;
flex: 3;
width: 100%;
justify-content: center;
padding: 2rem 0;
/* margin-top: 5%; */
`
const DataCard = styled.div`
display:flex;
/* flex:3; */
width: 100%;
padding: 1rem 0;
/* color: #4f4c4c; */
font-weight: 100;
/* &:hover{
  background-color:#f9f6ee;

} */

`
const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex:1;
  justify-content:center;
  h4{
    font-size:1.3rem;
    /* font-weight: 500; */
    text-align: center;
  }

`
const FlexWrapper1 = styled.div`
  display: flex;
  flex-direction: row;
  flex:0.5;
  justify-content:center;

`
const EditOrderButton = styled.button`

`
const CancelOrderButton=styled.button`
font-size: medium;
padding: 0 2rem;
height: 4rem;
border-radius: 20px;
border: 2px solid #F7707D;
cursor: pointer;
&:hover{
background-image:linear-gradient(90deg, #F7707D 0%, #F29173 100%);
color:white;
border: none;
}
@media ${b.mobile}{
/* margin-top: 10px; */
}
`

const MiniHeader = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;
padding: 0 3rem;
/* color: ; */
p{
  font-size: 1.8rem;
  font-weight: 600;
}
@media ${b.mobile}{
font-size: 1.5rem;
  font-weight: 600;
}


`
const CancelIcon = styled(Cancel)`
 color: #F7707D ;


&:hover{
  color: #f72d42 ;
  /* opacity: 0.8; */
  cursor: pointer;
}
`
import React from 'react'
import styled from 'styled-components';
import { useMediaQuery } from '../../hooks/mediaquery';
import {b} from "../../styles/variables"
import { useDispatch,useSelector } from 'react-redux';
import { getVendors } from '../../redux/feature/vendorSlice';
import { useEffect } from 'react';
import { Container ,NavLink, TextContainer} from '../../styles/globalStyles';
import { Button,FlxCenter } from '../../styles/globalStyles';
import { useState } from 'react';
import VendorCard from '../VendorCard';
import img from '../../assets/images/Home.jpg' 
import { useNavigate } from 'react-router-dom';
import {KeyboardArrowRight} from '@styled-icons/material-sharp/KeyboardArrowRight';
import { getUserInfo } from '../../redux/feature/authSlice';
import { getTotals } from '../../redux/feature/cartSlice';

function Home() { 

  const navigate = useNavigate()
  const auth=useSelector((state) => state.auth)
  const cart=useSelector((state) => state.cart)
  const dispatch = useDispatch()


  const foodSource = "VEG"


return (  
<MainContainerWrapper>
    <BackgroundImgContainer img={img}> 
   
<MainContainer>

</MainContainer>

<HeaderContainer>
 <h1>Freespace Food Ordering App</h1>
</HeaderContainer>

<ClickableContainer onClick={()=>navigate(`vendors`)}>
By Restaurant <KeyboardArrowRight size={30}/>
</ClickableContainer>
<Breakfast onClick={()=>navigate("/fooddata/food_avail/1")}>Order Breakfast <KeyboardArrowRight size={30}/></Breakfast>
<Lunch onClick={()=>navigate("/fooddata/food_avail/2")}>Order Lunch <KeyboardArrowRight size={30}/></Lunch>
<Snacks onClick={()=>navigate("/fooddata/food_avail/3")}>Order Snacks <KeyboardArrowRight size={30}/></Snacks>
<VegContainer onClick={()=>navigate(`/fooddata/food_source/${foodSource}`)}>
  Only Vegetarian <KeyboardArrowRight size={30}/></VegContainer>


</BackgroundImgContainer>
</MainContainerWrapper> 
  )
}

export default Home

const MainContainerWrapper = styled.div`
width: 100%;
height:100%;
background-color:#f6f4f0;

`

const BackgroundImgContainer=styled.div`
position: relative; 
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
/* justify-content:center; */
@media ${b.mobile}{
/* justify-content: center; */
}

&:before {
content:"";
background-image: url(${img});
background-size: 100%;
position: absolute;
right: 0px;
bottom: 0px;
left: 0px;
opacity: 0.6;
top: 0px;

}


`
const MainContainer = styled.div`
position: absolute;
  background-color: #1d1c1c;  
  /* background: linear-gradient(270deg, #F7707D 0%, #F29173 100%); */ 
  box-shadow: 0 0 4px 0 #000;
  width: 70%;
  height: 400px;
  opacity:0.5;
  display: flex;
  padding-top: 2%;
  /* top: calc((100% - 560px)/2); */
  top: calc((100% - 460px)/2);
  /* justify-content: center; */
  @media ${b.mobile}{
    min-width: 83%;
    min-height: 70%;
    top:calc(((100% - 60px) - 70%)/2);
}

`;

const HeaderContainer = styled.div`
//background-color:#cdcbc6 ;
background-color:#ffffff ;
/* background:linear-gradient(270deg, #F7707D 0%, #F29173 100%); */
position:absolute;
/* top: 16%; */
top: calc((100% - 460px)/2 + 20px);
width:40%;
padding: 1.8rem 4rem;
display: flex;
justify-content: center;
color:#180270 ;
font-size:2rem;
/* color: white; */

@media ${b.mobile}{
  width:60%;
  padding: 1rem 0;
  /* position:relative; */
  top: calc(((100% - 60px) - 70%)/2 + 5%);
  font-size: 1.5rem;
}

`
const Dummy = styled.div`
@media ${b.desktop}{
 
}

`

const ClickableContainer = styled.div`
background-color:#ffffff ;
//background:linear-gradient(270deg, #F7707D 0%, #F29173 100%);
position:absolute;
top: calc((100% - 460px)/2 + 150px);
left:calc((30%)/2 - 75px);
display: flex;
justify-content:center;
align-items:center;
width: 150px;
color:#180270 ;
transition: all .2s ease-out;
font-weight:300;
text-align: center;
min-height: 100px;
font-size:1.7rem;
&:hover{  
  font-size: 2.1rem;
  box-shadow: 0 0 4px 0 #000;
  cursor: pointer;
  opacity:0.9;
  transform: translate(0, -5px);
  border-radius: 1rem;  
  color: #02353f;
  
}

@media ${b.mobile}{
top:calc(((100% - 60px) - 70%)/2 + 15%);
left:calc(50% - 35vw);
min-height: 40px;
min-width: 70%;
font-size: 1.5rem;
}
`
const VegContainer = styled(ClickableContainer)`
left: calc(70% + (30%/2) - 75px);

@media ${b.mobile}{
top:calc(((100% - 60px) - 70%)/2 + 25%);
left:calc(50% - 35vw);

}

`;
const Breakfast = styled(ClickableContainer)`
left:calc((30%)/2 + 50px);
top: calc((100% - 60px)/2 + 150px);

@media ${b.mobile}{
top:calc(((100% - 60px) - 70%)/2 + 35%);
left:calc(50% - 35vw);

}

`;
const Lunch = styled(ClickableContainer)`
left:calc(35% + (30%/2) - 75px);
top: calc((100% - 60px)/2 + 150px);
@media ${b.mobile}{
top:calc(((100% - 60px) - 70%)/2 + 45%);
left:calc(50% - 35vw);

}

`;
const Snacks = styled(ClickableContainer)`
left:calc(70% + (30%/2) - 200px);
top: calc((100% - 60px)/2 + 150px);

@media ${b.mobile}{
top:calc(((100% - 60px) - 70%)/2 + 55%);
left:calc(50% - 35vw);

}

`;
const ByVendorButton = styled.button`

`;







import styled from "styled-components"
import {b} from "../variables"


export const Grid = styled.div`
display: grid;
grid-template-columns: 1fr repeat(12,minmax(auto,4.2rem)) 1fr;
grid-template-rows: max-content;
gap: ${(props)=>(props.gapDefault?props.gapDefault:"2rem 2rem")};
margin: ${(props)=>(props.marginDefault?props.marginDefault:0)};
`




import React from 'react';
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "../MsClient/authConfig";
import UserLogin from '../components/Login';

function LoginPage() {
    
  return (
   <UserLogin/>
  )
}

export default LoginPage


import React from 'react';
import { createRoot } from "react-dom/client";
import store from './redux/store';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./MsClient/authConfig";
import { StrictMode } from "react";
import { Provider } from 'react-redux';

const msalInstance = new PublicClientApplication(msalConfig);
const root = createRoot(document.getElementById("root"));


root.render(
  //<StrictMode>
     <Provider store = {store}>
    <MsalProvider instance={msalInstance}>  
   
  <BrowserRouter>
    
         
      <Routes>        
        <Route path="/*" element={<App />} />
      </Routes>
    
   
  </BrowserRouter>
  
  </MsalProvider>
  </Provider>
//</StrictMode>

);


import { createSlice } from "@reduxjs/toolkit";
import {addDays} from 'date-fns';

const billingSlice = createSlice({
    name: "billing",
    initialState: {
        dateRange:{
            startDate:addDays(new Date(), -1).toLocaleDateString('en-GB').split('/').reverse().join('-'),
            endDate:addDays(new Date(), -1).toLocaleDateString('en-GB').split('/').reverse().join('-')
        },
        
    },
    reducers: {
        setDate(state,action) {
            state.dateRange.startDate=action.payload.startDate
            state.dateRange.endDate=action.payload.endDate
        },
        clearDate(state){
            state.datesRange.startDate=null
            state.datesRange.endDate=null
        }
        
    },
});

export const {setDate,clearDate} = billingSlice.actions;
export default billingSlice.reducer;
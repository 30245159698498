import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FlxCenter, IconContainer,TextContainer } from '../../styles/globalStyles'
import { b, v } from '../../styles/variables'
import {Link, useNavigate} from 'react-router-dom'
import {Location} from '@styled-icons/evil/Location'
import {Restaurant} from '@styled-icons/ionicons-sharp/Restaurant'
import {FoodTag} from '@styled-icons/boxicons-regular/FoodTag'


function VendorCard({vendor}) {
 const navigate = useNavigate()
 const imgpath= require("../../"+vendor.vendor_image)
//  console.log(imgpath)
  return (
    <CardContainer onClick={()=>navigate(`/fooddata/foodbyvendor/${vendor.vendor_id}`)}>
<MainWrapperContainer>
  <ImgContainer>  
  <VendorImg src={imgpath}/>    
  </ImgContainer>
  <TitleContainer>
  {/* <IconContainer><Restaurant size={30} color={"Red"}/></IconContainer> */}
    <TextContainer fs={"1.9rem"} fc={"#180270"} fw={700}>
     {vendor.vendor_name} 
    </TextContainer>
    </TitleContainer>
    <BodyContainer>
<LocationContainer>
  {/* {console.log(vendor.vendor_id)} */}
<IconContainer><Location size={25} color={"#38d68b"}/></IconContainer><TextContainer fs={"1.8rem"} fc={"#757477"} fw={100}>{vendor.vendor_location}</TextContainer>
</LocationContainer>
<DescriptionContainer>
<FoodTag size={25} color={"green"}/>
{vendor.vendor_type==="2" && <><FoodTag size={25} color={"#d40000"}/></>}
</DescriptionContainer>
    </BodyContainer>
 

</MainWrapperContainer>
</CardContainer>
    
  )
}

export default VendorCard

const MainWrapperContainer = styled.div`
display: flex;
flex-direction: column;
border-radius: 20px;
background:white;
/* box-shadow:  0 0 0 0px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%); */
padding:1rem;
justify-content:space-evenly;

&:hover{
  transform: translate(0, -5px);
  cursor: pointer;
  /* box-shadow:  0 0 0 0px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%); */
  box-shadow: rgba(28,28,28,0.12) 0px 0.4rem 1.8rem;
}

`;
const CardContainer = styled.div`
display: flex;
flex-direction: column;
padding: 1rem 2rem;
width: 320px;
height: 300px;
margin-bottom: 6rem;
`;

const ImgContainer = styled.div`

margin-bottom: 5px;
padding: 5px;
overflow: hidden;
height: 200px;

`
const VendorImg = styled.img`
    width:100%;
    height:100%;
    /* object-fit:cover; */
    border-radius: 10%;
    
    
`;
const TitleContainer=styled.div` 
display  : flex;
 flex-direction: row;
 width:100%;
 padding: 5px 10px;
 justify-content: center;
`
const BodyContainer=styled.div`
height: 100%;
flex: 3;
display: flex;
width: 100%;
flex-direction:row;
padding: 0 2rem;
`
const LocationContainer = styled.div`
display: flex;
flex-direction: row;
align-items:center;
justify-content: left;
width: 100%;

`
const DescriptionContainer = styled.div`
display: flex;
flex-direction: row;

`
const NavLinkContainer = styled.div`
display: flex;
${FlxCenter};
width:100%;





`
const Button = styled.button`
background:whitesmoke;
border: 2px solid #fb743e;
color:#fb743e;
border-radius: 14px;
padding: 7px 60px;
font-weight: 600;
cursor: pointer;

:hover{
 background-image:linear-gradient(90deg, #F7707D 0%, #F29173 100%);
 transform: translate(0, 2px);
 color:white;
}



`
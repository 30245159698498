import React from 'react'
import styled from "styled-components";
import Modal from "styled-react-modal";
import { useState } from 'react';
import { TextContainer } from '../../styles/globalStyles';
import {CloseOutline as Close} from '@styled-icons/evaicons-outline/CloseOutline'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { updateUserLoc } from '../../redux/feature/authSlice';
import { useDispatch } from 'react-redux';
import { getUserInfo } from '../../redux/feature/authSlice';

const options = ["Coimbatore","Mumbai"];

function MyLocation() {  
    const navigate = useNavigate()
    const auth=useSelector((state) => state.auth)
    const dispatch=useDispatch()
    const [isOpen, setIsOpen] = useState(true);
    const [opacity, setOpacity] = useState(0);
  
    function toggleModal(e) {
      setOpacity(0);
      setIsOpen(!isOpen);      
      // if (isOpen===true){navigate('/')}
    }
  
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
      });
    }


  const [isDropOpen, setIsDropOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(auth.creds.userlocation === '' ? "Mumbai" : auth.creds.userlocation);

  const toggling = () => setIsDropOpen(!isDropOpen);

  const onOptionClicked = value => () => {
    //console.log(value)
    setSelectedOption(value);
    setIsDropOpen(false);
    //console.log(selectedOption);
  };

  return (  
     
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        // onBackgroundClick={toggleModal}
        //onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
      >
        <MainContainer>
        <Header>
           <TextContainer fc={"white"} fs={"1.2rem"}>Hi {auth.creds.username.split(' ')[0]}, Please Select your Location</TextContainer> 
            <CloseButton onClick={()=>{toggleModal()
                                        navigate('/')}} disabled = {auth.creds.userlocation === '' ? true : false}><Close size={20} /></CloseButton>
        </Header>
        <BodyContainer onClick={toggling}>
        <h1>Please select your preferred location</h1>
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>
          {selectedOption || "Mumbai"}
        </DropDownHeader>
        {isDropOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map(option => (
                <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                  {option}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
                <SubmitButton onClick={()=>{
                  //console.log(selectedOption)
                dispatch(updateUserLoc(selectedOption))
                //dispatch(getUserInfo())
                toggleModal()
                navigate('/')
                // window.location.reload()
                
                }}>Submit</SubmitButton>
        </BodyContainer>
        </MainContainer>
      </StyledModal>
      
  )
}

export default MyLocation

const StyledModal = Modal.styled`
    width: 30rem;
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    opacity: ${(props) => props.opacity};
    transition : all 0.3s ease-in-out;
    border-radius:2.5rem;
    `;


    const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    `;
    const Header = styled.div`
    width:100%;
    height:3.5rem;
    background: linear-gradient(90deg, #F7707D 0%, #F29173 100%);
    border-radius: 2.5rem 2.5rem 0 0; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2rem;
    align-items: center;
    color: white;
    
    `
    const CloseButton = styled.button`
    &:hover{
        cursor: pointer;
        color: #a30c32;
    }
    
    `

    const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    height: 100%;
h1{
    margin: 1rem;
}

    `

const DropDownContainer = styled("div")`
width: 10.5em;
margin: 0 auto;
`;

const DropDownHeader = styled("div")`
margin-bottom: 0.5em;
padding: 0.4em 2em 0.4em 1em;
box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
font-weight: 500;
font-size: 1.3rem;
color: #3faffa;
`;

const DropDownListContainer = styled("div")`
position: absolute;
z-index: 100;
width: 10.5em;
`;

const DropDownList = styled("ul")`
padding: 0;
margin: 0;
padding-left: 1em;
background: #ffffff;
border: 2px solid #e5e5e5;
box-sizing: border-box;
color: #3faffa;
font-size: 1.3rem;
font-weight: 500;
&:first-child {
  padding-top: 0.8em;
}
`;

const ListItem = styled("li")`
list-style: none;
margin-bottom: 0.8em;
&:hover {
  color: #fd9e46;
}
`;

const SubmitButton = styled.button`
background: linear-gradient(90deg, #F7707D 0%, #F29173 100%); ;
padding: 0.8rem 3rem;
margin-top: 1rem;
border-radius: 3rem;
border: none;
color: whitesmoke;
&:hover{
    opacity: 0.9;
}
`


import React from 'react'
import styled from 'styled-components'
import { TouchBallLoading } from 'react-loadingg';
function Loader() {
  return (
    <LoaderContainer>
        <TouchBallLoading color={"#F7707D"} size={"large"} speed={1}/>
    </LoaderContainer>

  )
}

export default Loader

const LoaderContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${(theme)=>theme.bg4};

`

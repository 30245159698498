import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
    name: "menu",
    initialState: {       
      menu:{},      
      fetchingData:false
    },
    reducers:{        
        getMenu: (state,action)=>{            
            state.fetchingData=true
        },
        
        setMenu: (state,action)=>{            
            state.menu=action.payload
            state.fetchingData=false
        },

        searchItem: (state,action)=>{            
            state.fetchingData=true
        },

        clearMenu:(state)=>{
            state.menu={}
        },
       


    }
})

export default menuSlice.reducer;
export const { getMenu,setMenu,clearMenu, searchItem} = menuSlice.actions 
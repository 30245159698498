import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {b, v} from "../styles/variables";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { MobileHide, TabletHide } from "../styles/globalStyles";
import {useSelector} from 'react-redux';
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUserInfo } from "../redux/feature/authSlice";
import Loader from "./Loader";
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import MyLocation from "./MyLocation/Index";
import { getTotals } from "../redux/feature/cartSlice";
import { sideBarOff } from "../redux/feature/uiSlice";

const RequireAuth = ({ allowedRoles }) => { 
  const dispatch = useDispatch()   
  const auth=useSelector((state) => state.auth)
  const {sidebarState} = useSelector((state) => state.ui);
  const loading=auth.isloading
  let userLocation = auth.creds.userlocation
 const location = useLocation();
 const cart=useSelector((state) => state.cart)
//console.log(sidebarState)
//  useEffect(() => {
//    dispatch(getTotals(auth.creds.usercredit));
//  }, [cart, dispatch]);
 

    return (<>{loading? <Loader/> :
    <AppContainer>
      {auth?.isAuthenticated && <SideBarContainer sidebarState={sidebarState}><Sidebar/></SideBarContainer>}
      <Main onClick={()=>{sidebarState && dispatch(sideBarOff())}}>
       {auth?.isAuthenticated && <NavBarContainer><Navbar/></NavBarContainer>}
       <ModalProvider backgroundComponent={FadingBackground}>
        <BodyContainer> {         
         auth?.isAuthenticated
            ? (auth?.creds?.roleslist?.find(role => allowedRoles?.includes(role))? 
            (userLocation === "" ? <MyLocation/> : <Outlet/>) :
            <Navigate to="/unauthorized" state={{ from: location }} replace />) :            
             <Navigate to="/login" state={{ from: location }} replace />}
            </BodyContainer>
            </ModalProvider>
      
          </Main>
       </AppContainer>       
      } </>);
}

export default RequireAuth;


const AppContainer = styled.div`
width:100vw;
height: 100vh;
font-family: nunito sans-serif;

 `
 const Main = styled.div`  
 position: fixed;
 top: 0;
 right: 0;
 bottom: 0;
 left:17%;
 @media ${b.mobile}{
left:0
}

 `

const SideBarContainer = styled.div`
//background:#FF597B;
background: ${({theme})=>theme.bg};
height: 100%;
position: fixed;
top: 0;
left: 0;
right:83%;
z-index: 1000;


@media ${b.mobile}{
 display: ${({sidebarState})=>sidebarState? 'block' : 'none'};
 right: 50%;
}

  `;
const NavBarContainer = styled.nav`
display: flex;
background: white;
height: 65px;
border-bottom: 1px solid gray;
`;

const BodyContainer = styled.div`
 width: 100%;
 height:100%; 
 background: #F5F5F5;
 @media ${b.mobile}{
padding: 0 ;
}

`;

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;


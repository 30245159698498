import React from 'react'
import logo from '../../assets/Sidebar-logo.png'
import styled from 'styled-components';
import { FlxCenter } from '../../styles/globalStyles';
import {AccountData, AdminData, MyData} from './SidebarData';
import {TextContainer } from '../../styles/globalStyles';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROLES } from '../../App';
import { sideBarOff } from '../../redux/feature/uiSlice';
import {Cross as Cancel } from '@styled-icons/entypo/Cross'
import {b} from '../../styles/variables'
import { useDispatch } from 'react-redux';

function Sidebar() {
const cart = useSelector(state=>state.cart)
const auth=useSelector((state) => state.auth)
const {sidebarState} = useSelector((state) => state.ui);
const dispatch = useDispatch()
//console.log(auth.creds.roleslist.includes(ROLES['Admin']))
//console.log(auth.creds.roleslist.includes(ROLES['AdminRead']))
  return (
    <MainContainer>  
    <CancelWrapper><CancelIcon size={30} onClick={()=>{dispatch(sideBarOff())}}/></CancelWrapper>   
    <LogoContainer>
    <Logo src={logo} /> 
    <p>Welcome to the Food App</p>
    </LogoContainer>
    
    <BodyContainer>
      <HeaderContainer>My Tabs</HeaderContainer>
    <RoutesContainer>
    <ULContainer>
    {MyData.map((item, index) => {
              return (
                <LIContainer key={index}>
                  <NavLink to={item.path} style={({ isActive }) => {
                    return{
                      color: isActive ? '#59FFDD' : '#d1cdcd' }}}>
                    <RouteContainer>
                      <IconContainer>{item.icon}</IconContainer>
                    
                    <span>{item.title}</span>
                    {item.title==="Cart" && <CircleContainer>{cart.cartTotalQuantity}</CircleContainer>}
                    </RouteContainer>
                  </NavLink>
                
                </LIContainer>
              );
            })}
      </ULContainer>
    </RoutesContainer>

   {(auth?.creds?.roleslist?.includes(ROLES['Admin']) || auth?.creds?.roleslist?.includes(ROLES['AdminRead'])) && <> <HeaderContainer>Live Orders</HeaderContainer>
    <RoutesContainer>
    <ULContainer>
    {AdminData.map((item, index) => {
              return (
                <LIContainer key={index}>
                  <NavLink to={item.path}   style={({ isActive }) => {
                    return{
                      color: isActive ? '#59FFDD' : '#d1cdcd' }}}>
                    <RouteContainer>
                      <IconContainer>{item.icon}</IconContainer>
                    
                    <span>{item.title}</span>
                    {item.title==="Cart" && <CircleContainer>{cart.cartTotalQuantity}</CircleContainer>}
                    </RouteContainer>
                  </NavLink>
                
                </LIContainer>
              );
            })}
      </ULContainer>
    </RoutesContainer> </>} 

    {auth?.creds?.roleslist?.includes(ROLES['Finance']) && <> <HeaderContainer>Accounts</HeaderContainer>
    <RoutesContainer>
    <ULContainer>
    {AccountData.map((item, index) => {
              return (
                <LIContainer key={index}>
                  <NavLink to={item.path} style={({ isActive }) => {
                    return{
                      color: isActive ? '#38D68B' : '#d1cdcd' }}}>
                    <RouteContainer>
                      <IconContainer>{item.icon}</IconContainer>
                    
                    <span>{item.title}</span>
                    {item.title==="Cart" && <CircleContainer>{cart.cartTotalQuantity}</CircleContainer>}
                    </RouteContainer>
                  </NavLink>
                
                </LIContainer>
              );
            })}
      </ULContainer>
    </RoutesContainer>  </>}
    </BodyContainer>
    <FooterContainer>

    </FooterContainer>
    </MainContainer>
  )
};

export default Sidebar;
const MainContainer = styled.div`
width:100%;
height: 100%;
display: flex;
flex-direction: column;
color: #d1cdcd;
`
const CancelWrapper = styled.div`
display:flex;
width: 100%;
justify-content: right;
padding: 0.2rem 0.2rem;

@media ${b.desktop}{
display: none;
}
`;
const LogoContainer = styled.div`
display: flex;
flex-direction:column;
${FlxCenter}

  font-size: 1.4rem;
  text-align: center; 
  font-weight: 300;
  margin-top: 20px;

    & p{           
      font-weight: 400;     
      margin-bottom:5rem;
    }
`

const Logo = styled.img`
    width: 140px;
    height: 30px;
    cursor: pointer;
    
    `
const CancelIcon = styled(Cancel)`
color: #7d7a7a;


&:hover{ 
 cursor: pointer;
}
`

  const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
  align-items:left;
  padding: 0 2rem;
  line-height: 1.42857143;
  font-size: 1.2rem;
  `
  const HeaderContainer = styled.div`
  color: #7d7a7a;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  `

  const FooterContainer = styled.div`
  display: flex;
  flex: 2;
  position: fixed;
  z-index: 2000;
  background: #09002b;
  `
  const RoutesContainer = styled.div`
    display: flex;
    width:100%;    
    cursor: pointer; 
       

  `
  const ULContainer = styled.ul`
  width:100%;
  `

  const LIContainer = styled.li`
  padding-bottom: 15px;
  `
  const RouteContainer = styled.div`
    display: flex;
    flex-direction: row;
  

  `

  const CircleContainer = styled.div`
  display:flex;
  width:15px;
  height:15px;
  padding: 1%;
  border-radius: 50%;
  background-color: #8A59FF;
  margin-left:5px;

  font-size:1.4rem;
  text-align: center;
  align-items:center;
  justify-content: center;
  font-weight:600;
  &:hover{   
    color:#eff6f3;
    background-color:#59FFDD;
}  
  `
 const MyNavLink = styled(NavLink)`
 text-decoration: none;
 
&:hover{
  color:#59FFDD;
}
 `
 const IconContainer = styled.div`
 margin-right: 4%;
 
 `
  
  
import { takeLatest,put,fork,call, takeEvery } from "redux-saga/effects";
import { placeOrderApi,getMyOrderApi,getLiveOrdersApi, mapBulkTokenApi, updateCreditApi,cancelItemApi,getBulkTokenApi,cancelOrderApi } from "../api/allAxios";
import {placeOrder,orderPlaced,getMyOrder,setMyOrder, getLiveOrders,setLiveOrders, mapBulkToken, updateCredit,cancelItem,cancelOrder, getBulkToken, setBulkToken, cancelItemSuccess, cancelItemFail,cancelOrderSuccess, cancelOrderFail} from '../redux/feature/orderSlice'
import {getTotals } from "../redux/feature/cartSlice";
import { getUserInfo, updateUserInfo } from "../redux/feature/authSlice";
import {clearCart} from '../redux/feature/cartSlice';
import {clearMenu} from '../redux/feature/menuSlice';
import {clearVendorList} from '../redux/feature/vendorSlice';
import {clearOrderData} from '../redux/feature/orderSlice';
import { logOutUser } from "../redux/feature/authSlice";
import { toast } from "react-hot-toast";

function* placeOrderAsync(action) {
    try {     
     const order = action.payload[0]              
     const response =  yield call(placeOrderApi,order)
     const { data } = response 
     //console.log(data)
     if (data.length===0){
      toast.error("You cannot place order at this time of the day",
          {
            position: 'bottom-right',
            duration: 3000,
            style:{
              width:"auto"
            }
        
        })
        yield put(clearCart());
        yield put(getTotals()); 
     }
     else{
      yield put(orderPlaced(data));     
      yield put(clearCart());
      yield put(getTotals());
      yield put(getUserInfo()); 
     }    
    } catch (error) {
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }
    }
  }

  function* myOrderAsync(action) {
    try {     
       const myId=action.payload 
     const response =  yield call(getMyOrderApi)
     const { data } = response 
     const myData=data.filter((order)=>{
return order.user_id ===myId
     })
     //console.log(myData)
      yield put(setMyOrder(myData)); 
          
    } catch (error) {
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }
    }
  }

  function* getLiveOrdersAsync() {
    try {     
      //console.log("i am getting orders")  
     const response =  yield call(getLiveOrdersApi)
     const { data } = response 
     //console.log(data)
      yield put(setLiveOrders(data)); 
          
    } catch (error) {
      //console.log(error)
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }
    }
  }

  function* mapBulkTokenAsync(action) {
    try {     
       
     const response =  yield call(mapBulkTokenApi,action.payload)
     const { data } = response 
     //console.log(data)
      yield put(getBulkToken()); 
          
    } catch (error) {
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }
    }
  }

  function* updateCreditAsync(action) {
    try {  
      //console.log(action.payload)    
     const response =  yield call(updateCreditApi,action.payload)
     const { data } = response 
     //console.log(data)
    yield put(getUserInfo());
          
    } catch (error) {
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }
    }
  }

  function* cancelItemAsync(action) {
    try { 
      const food_id=action.payload[0]       
      const userId = action.payload[1]
      //console.log(food_id)    
     const response =  yield call(cancelItemApi,food_id)
     const { data } = response 
     //console.log(data)
     if (data==="Success"){      
      yield put(cancelItemSuccess())
      yield put(getUserInfo())
      //yield put(getMyOrder(userId))
     }   
     
    } catch (error) {
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }
      if(status===406){
        yield put(cancelItemFail())
      }
    }
  }

  function* cancelOrderAsync(action) {
    try { 
      const order_id=action.payload         
     const response =  yield call(cancelOrderApi,order_id)
     const { data } = response 
     //console.log(data)
     yield put(cancelOrderSuccess()) 
     yield put(getUserInfo())
          
    } catch (error) {
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }
      if(status===406){
        yield put(cancelOrderFail())
      }
    }
  }

  function* getBulkTokenAsync() {
    try {     
      // console.log("i am getting orders")  
     const response =  yield call(getBulkTokenApi)
     const { data } = response 
     //console.log(data)
      yield put(setBulkToken(data)); 
          
    } catch (error) {
      //console.log(error)
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }
    }
  }



  function* watchPlaceOrder() {
    //console.log("Inside Order Saga")
    yield takeEvery(placeOrder.type, placeOrderAsync);
  }

  function* watchMyOrder() {
    //console.log("Inside My Order Saga")
    yield takeEvery(getMyOrder.type, myOrderAsync);
  }

  function* watchGetLiveOrders() {
    //console.log("Inside Liveorder Saga")
    yield takeEvery(getLiveOrders.type, getLiveOrdersAsync);
  }
  
  function* watchMapBulkToken() {   
    yield takeEvery(mapBulkToken.type, mapBulkTokenAsync);
  }
  function* watchUpdateCredit() {   
    yield takeEvery(updateCredit.type, updateCreditAsync);
  }

  function* watchCancelItem() {   
    yield takeEvery(cancelItem.type, cancelItemAsync);
  }

  function* watchCancelOrder() {   
    yield takeEvery(cancelOrder.type, cancelOrderAsync);
  }
  
  function* watchGetBulkToken() {   
    yield takeEvery(getBulkToken.type, getBulkTokenAsync);
  }
  export const orderSagas = [fork(watchPlaceOrder),fork(watchMyOrder),fork(watchGetLiveOrders),
    fork(watchMapBulkToken),fork(watchUpdateCredit),fork(watchCancelItem),fork(watchGetBulkToken),fork(watchCancelOrder)]
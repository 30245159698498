import { takeLatest,put,fork,call, takeEvery } from "redux-saga/effects";
import { authApi,updateLocationApi,userInfoApi} from "../api/allAxios";
import {login,loginSuccess,loginFail, getUserCreds,getUserInfo,updateUserInfo, updateUserLoc,logOutUser} from '../redux/feature/authSlice';
import {clearCart} from '../redux/feature/cartSlice';
import {clearMenu} from '../redux/feature/menuSlice';
import {clearVendorList} from '../redux/feature/vendorSlice';
import {clearOrderData} from '../redux/feature/orderSlice';
import { toast } from "react-hot-toast";

function* authenticateUserAsync(action) {
    try {
    const username=action.payload.name
    const useremail=action.payload.username
    const response = yield call(authApi,useremail,username);
    //(response.data)   
    
      const { data } = response;
      
      yield put(loginSuccess(data));
      
    } catch (error) {
     //console.log(error.message);
      
      yield put(loginFail({error}));

    }
  }


  function* getUserInfoAsync() {
    try {     
     
    const response = yield call(userInfoApi);
    //console.log(response.data)   
    
      const {data} = response;
      
      yield put(updateUserInfo(data));
      
    } catch (error) {
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }
    }
  }

  function* updateUserLocAsync(action) {
    try {     
     
    const response = yield call(updateLocationApi,action.payload);
    //console.log(response.data)   
    
      const {data} = response;
      
      yield put(updateUserInfo(data));
      
    } catch (error) {
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }

    }
  }

  export function* watchAuthenticateUser() {
    //console.log("Inside Auth Saga")
    yield takeEvery(getUserCreds.type, authenticateUserAsync);
  }
  
  export function* watchGetUserInfo() {
    //console.log("Fetching User Data")
    yield takeEvery(getUserInfo.type, getUserInfoAsync);
  }

  export function* watchUpdateLoc() {
    //console.log("Fetching User Data")
    yield takeEvery(updateUserLoc.type, updateUserLocAsync);
  }

  export const authSagas = [fork(watchAuthenticateUser),fork(watchGetUserInfo),fork(watchUpdateLoc)]
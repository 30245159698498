import React from 'react'
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getLiveOrders,mapBulkToken } from '../../redux/feature/orderSlice';
import { useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';
import {b} from "../../styles/variables";
import {NavLink, useNavigate} from 'react-router-dom';
import VendorOrders from '../VendorOrders';
import { ROLES } from '../../App';
function ByVendor() {
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const allOrders=useSelector((state)=>state.order)
    const auth = useSelector(state=>state.auth)    
    const liveOrders=allOrders.liveOrders
    const vendorBased=allOrders.vendorBased
    const bulkToken = allOrders.bulk_token
    
  
  
  const handlePlaceBulkOrder=(filter_type)=>{
    dispatch(mapBulkToken(filter_type))
    //navigate(0)
  }
  
  const [orderType,SetOrderType] = useState(!localStorage.getItem("currentVendorOrderPage")
                                            ?"Breakfast" : localStorage.getItem("currentVendorOrderPage")
                                          )
  const [B,setB]=useState(localStorage.getItem("currentVendorOrderPage") ? (localStorage.getItem("currentVendorOrderPage") ==="Breakfast" ? 1 : 0):1)
  const [L,setL] = useState(localStorage.getItem("currentVendorOrderPage") ==="Lunch" ? 1 : 0)
  const [S,setS] = useState(localStorage.getItem("currentVendorOrderPage") ==="Snack" ? 1 : 0)
  const orderData = orderType=== "Breakfast" ? vendorBased?.BREAKFAST :
                    orderType=== "Lunch" ? vendorBased?.LUNCH :
                    vendorBased?.SNACK
// console.log(orderData)
// console.log(myUserId)



const handleClickB =(type)=>{
  setB(1)
  setL(0)
  setS(0)
  localStorage.setItem("currentVendorOrderPage",type)
  SetOrderType(type)
}
const handleClickL =(type)=>{
  setB(0)
  setS(0)
  setL(1)
  localStorage.setItem("currentVendorOrderPage",type)
  SetOrderType(type)
}
const handleClickS =(type)=>{
  setB(0)
  setS(1)
  setL(0)
  localStorage.setItem("currentVendorOrderPage",type)
  SetOrderType(type)
}
  
  // const conditionData = orderType=== "Breakfast" ? vendorBased?.BREAKFAST :
  //                     orderType=== "Lunch" ? vendorBased?.LUNCH :
  //                     vendorBased?.SNACK
  //console.log(liveOrders[orderType.toUpperCase()][0].bulktoken_no)
return (
    <MainContainer>
    <HeaderWrapper>
  <HeaderContainer>
    <CustomNavlink livestate={B} onClick={()=>handleClickB("Breakfast")}>Breakfast</CustomNavlink>
    <CustomNavlink livestate={L} onClick={()=>handleClickL("Lunch")}>Lunch</CustomNavlink>
    <CustomNavlink livestate={S} onClick={()=>handleClickS("Snack")}>Snacks</CustomNavlink>      
  </HeaderContainer>
  
  { (auth?.creds?.roleslist?.includes(ROLES['Admin']) && liveOrders[orderType.toUpperCase()]?.length!==0 )&& <LockOrderButton token={bulkToken[orderType.toUpperCase()]} disabled={bulkToken[orderType.toUpperCase()]==="" ? false : true} onClick={()=>handlePlaceBulkOrder(orderType)}>Lock {orderType} Order</LockOrderButton>}
  </HeaderWrapper>
  <WrapperContainer>
    
  <ByUserContainer>{liveOrders[orderType.toUpperCase()]?.length===0 ? <React.Fragment>There are no orders for {orderType}</React.Fragment> :
  (<>
   <MiniHeader><p>Vendor Based List</p> {bulkToken[orderType.toUpperCase()]!==""  && <TokenContainer>Bulk Order Token:  <b>{bulkToken[orderType.toUpperCase()]}</b> </TokenContainer>}
  </MiniHeader>
  <SubHeader>
    {/* <FlexWrapper1><h5>Sr.No.</h5></FlexWrapper1> */}
    {/* <FlexWrapper><h5>Vendor Name</h5></FlexWrapper> */}
    {/* <FlexWrapper><h5>Freespace Id</h5></FlexWrapper> */}
  </SubHeader>
  <SubBody>
  
    {      
    Object.keys(orderData)?.map((item)=>{
        //console.log(item)
      return <VendorOrders vendorName={item} vendorOrders={orderData[item]} orderType={orderType} key={Math.random()} />
    })}    
    
   
  </SubBody>
  <SubFooter>
 
  </SubFooter> </>) }
  </ByUserContainer>   
  
  </WrapperContainer>
</MainContainer>
  )
}

export default ByVendor

const MainContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
font-family: nunito sans-serif;

/* background-color:white; */
`
const WrapperContainer = styled.div`
display: flex;
height: 100%;
flex-direction: row;
justify-content: center;
align-items:center;
padding-top: 1%;
padding-bottom: 9%;
@media ${b.mobile}{
 padding: 0.5rem 0;
}

`;

const HeaderWrapper = styled.div`
display: flex;
flex-direction: row;
width: 100%;
background: white;
justify-content: space-between;
align-items: center;
padding: 2rem 2rem;
height: 70px;
@media ${b.mobile}{ 
  font-size:1.2rem;  
  height: auto;
  padding: 1rem 1rem;
}

`;

const HeaderContainer = styled.div`
display: flex;
flex-direction: row;
justify-content:left;
margin-bottom: 0.5rem;

`;


const ByUserContainer = styled.div`
display: flex;
flex-direction: column;
background-color: white;
height: 100%;
width:49%;
align-items: center;
color: black;
padding: 2rem 2rem;
@media ${b.mobile}{  
  width: 100%;
  padding: 0 1.2rem;
}
`;

const TokenContainer = styled.div`

font-weight: 500;
`
const CustomNavlink = styled(NavLink)`
text-decoration: none;
color: #393838;
font-size:${({livestate})=>livestate===1 ? "2.3rem" : "2.1rem"};
font-weight:600;
border-bottom:${({livestate})=>livestate===1 ? "5px solid green" : "0px"} ;
padding: 1rem 0;
margin-right: 3rem;
&:hover{
cursor: pointer;
}
@media ${b.mobile}{  
  font-size:${({livestate})=>livestate===1 ? "1.7rem" : "1.5rem"};
  padding: 0.3rem 0; 
  margin-right: 1.2rem;  
}
`;

const SubHeader = styled.div`
width: 100%;
padding: 0 0;
font-weight: 600;
display: flex;
background-color:#d9f8ec;
justify-content: left;
@media ${b.mobile}{  
  font-size:1.5rem;   
}

`
const SubBody = styled.div`
width: 100%;
height: 80%;
padding-bottom: 10rem;
overflow-y: auto;
@media ${b.mobile}{  
  padding-bottom: 12rem;   
}
`
const SubFooter = styled.div`

`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex:1;
  justify-content:left;

`
const FlexWrapper1 = styled.div`
  display: flex;
  flex-direction: row;
  flex:0.3;
  justify-content:left;

`
const LockOrderButton = styled.button`
font-size: medium;
height: 3rem;
padding: 0 1rem;
border-radius: 2.5rem;
border: 2px solid #F7707D;
cursor: pointer;
&:hover{
background:${({token})=>(token==="" ? "linear-gradient(90deg, #F7707D 0%, #F29173 100%)" : "#ece9e9")};
color:${({token})=>(token==="" ? "white" : "black")};


}
&:disabled{
  background-color: #ece9e9;
  border: none;

}

@media ${b.mobile}{
font-size: small;
padding: 0 0.5rem;
border-radius: 2rem;
}
`


const MiniHeader = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;

p{
  font-size: 1.8rem;
  font-weight: 600;
}

`

import {all} from 'redux-saga/effects';
import {authSagas} from './authSaga';
import { vendorSagas } from './vendorSaga';
import { menuSagas } from './menuSaga';
import { orderSagas } from './orderSaga';

export default function* rootSaga() {
    yield all([
      ...authSagas,...vendorSagas,...menuSagas,...orderSagas
    ])
  }
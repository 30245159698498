import React from 'react'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components'
import { getMenu } from '../../redux/feature/menuSlice';
import { useEffect, useCallback} from 'react';
import { useDispatch ,useSelector} from 'react-redux';
import MenuCard from '../MenuCard';
import { TextContainer } from '../../styles/globalStyles';
import Loader from '../Loader';
import {b} from '../../styles/variables'
function Menu(props) {
  const location = useLocation()
  const url = props.url  
  const params=(location.pathname.split("/"))
  //console.log(params[3])
  const dispatch=useDispatch()
  const {menu} = useSelector((state)=>state.menu)
  const menus = Object.values(menu)
  // const mainURL=  useCallback(function (params,url) {
  //   if (params.length===4){
  //     return url+ "/"+ params[3]
  //   }
  //   else{
  //     return url
  //   }
    
  // }, []);

  const mainURL =params.length===4? url+ "/"+ params[3]:url
  //console.log(mainURL)
  useEffect(() => {     
    dispatch(getMenu(mainURL))    
  }, [mainURL]);

 
//console.log(vendor.vendor_name)

  return (
    <MainContainer>
      { menu.fetchingData ? <Loader/> : 
      <WrapperContainer>
      {
      menus.length ===0 ? <><b>No Food Items to display</b></> : <>
      <HeaderContainer>
 
      </HeaderContainer>
 <CardsContainer>
 {menus.map((menu,index )=> {
   return( <React.Fragment key={index} >
     <MenuCard menu={menu} id={index} params={params} />
   </React.Fragment>
) })}
</CardsContainer>
</>
}
</WrapperContainer>
}
    </MainContainer>  
)
}

export default Menu

const MainContainer = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;

`
const WrapperContainer = styled.div`
//height: 100%;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
/* padding: 0 1rem 5% 1rem; */
/* background-color:white; */

@media ${b.mobile}{
 
  /* padding: 0 0 12% 0; */
}

`

const HeaderContainer = styled.div`
display: flex;
flex: 0;
background: white;
border-bottom: 1px solid "#180270";
width: 100%;
`
const CardsContainer = styled.div`
display: flex;
flex-direction: column;
flex: 9;
width: 60%;
overflow-y: auto;
overflow-x: hidden;
padding-bottom: 10%;
background: white;

@media ${b.mobile}{
  width: 100%;
  padding-bottom: 25%;
}
`



import React from 'react'
import styled from 'styled-components'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useState,useEffect} from 'react';
import {addDays} from 'date-fns';
import { useDispatch ,useSelector} from 'react-redux';
import { getVendors } from '../../redux/feature/vendorSlice';
import MyLocation from '../MyLocation/Index';
import { useNavigate } from 'react-router-dom';
import { setDate } from '../../redux/feature/billingSlice';
import {b} from '../../styles/variables'

function FinanceByDate() {
const {vendorList} = useSelector((state)=>state.vendors)
const auth=useSelector((state) => state.auth)
const {dateRange} = useSelector((state)=>state.billing)
// const [startDate, setStartDate] = useState(new Date());
// const [endDate, setEndDate] = useState(null);
console.log(dateRange)
const location = auth.creds.userlocation
const dispatch = useDispatch()
const vendors = Object.values(vendorList)
const navigate = useNavigate()



const onChange = (dates) => {
  const [start, end] = dates;
  dispatch(setDate({startDate:start,endDate:end}));
 
};
// useEffect(() => {
      
//   dispatch(getVendors(location))
  
// }, [location]);

const handleCalendarClose = () => {
  // console.log(startDate)
  // console.log(endDate)
  // if (startDate !==null && endDate !==null){
  //   navigate('/analysis/filters')
  // }
  // console.log(startDate)
  // console.log(endDate)
};
const handleCalendarOpen = () => {
  // console.log(startDate)
  // console.log(endDate)
//setDateRange([null,null])
}
const handleFetchData=()=>{


}

  return (
    <MainContainer>
        <Main>
        <HeaderContainer>
            Billing Reports
        </HeaderContainer>
        <BodyContainer>
          <DateLineContainer>    

            <DateContainer>
            <p>Select Date Range: </p>
            <CustomDatePicker
      selectsRange={true}
      startDate={new Date(dateRange.startDate)}
      endDate={new Date(dateRange.endDate) }
      minDate={new Date("2023-05-09")}
      maxDate={addDays(new Date(), -1)}
      dateFormat="dd/MM/yyyy"
      onChange={onChange}      
      onCalendarClose={handleCalendarClose}
      onCalendarOpen={handleCalendarOpen}
     
    />

</DateContainer>
<FetchButton onClick= {() => handleFetchData()}>Go</FetchButton>

</DateLineContainer>

<FilterContainer>

<FilterWrapper><VendorFilterContainer>


</VendorFilterContainer></FilterWrapper>

<FilterWrapper><MealFilterContainer>


</MealFilterContainer></FilterWrapper>

<FilterWrapper><UserFilterContainer>

</UserFilterContainer></FilterWrapper>

</FilterContainer>

            
        </BodyContainer>
        </Main>
        
        
    </MainContainer>
  )
}

export default FinanceByDate;


const MainContainer = styled.div`
  width: 100%;
  height: 100%; 
  display : flex;
  flex-direction: row;
  justify-content: center;
  font-family: nunito sans-serif;
  color: #180270;
`;

const Main = styled.div`
width:70%;
background: white;
padding: 0 1%;
`;

const HeaderContainer =styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
padding: 6px 0;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;

`;


const DateContainer = styled.div`
display:flex;
flex-direction:row;
width:40%;
justify-content: space-around;
p{
  
}
`;


const DateLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem 2rem;
  justify-content: space-between;
  padding-bottom: 5rem;
`;

const CustomDatePicker = styled(DatePicker)`
border : 1px solid gray;
text-align: center;
border-radius: 40px;

`;
const FilterContainer = styled.div`
display: flex;
flex-direction: row;
padding: 0 1%;
justify-content: space-between;
/* border: 1px solid gray;
border-radius: 20px; */
min-height:500px ;

`
const FilterWrapper = styled.div`
display:flex;
flex-direction: row;
border: 1px solid gray;
flex: 0.8;
margin: 0 1rem;

`
const VendorFilterContainer = styled.div`
  
`
const MealFilterContainer = styled.div`
  
`
const UserFilterContainer = styled.div`
  
`

export const FetchButton = styled.button`
background:linear-gradient(90deg, #F7707D 0%, #F29173 100%);
border-radius: 2.2rem;
padding: 10px 20px;
border: none;
color: white;
font-weight: 500;
font-size:1.3rem;
&:hover { 
  cursor: pointer;
}
@media ${b.mobile}{
  border-radius:1.5rem;
  font-size:80%;
}

`

import React from 'react'
import Home from '../components/Home'
import { useScrollToTop } from '../hooks/useScrollToTop';

function HomePage() {


  useScrollToTop()


  


  return (
    <Home/>
  )
}

export default HomePage;
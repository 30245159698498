import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
    name: "ui",
    initialState: {
        theme: "light",
        sidebarState: false,
    },
    reducers: {
        toggleTheme(state,action) {
            state.theme = state.theme === "light" ? "dark" : "light";
        },
        toggleSideBar(state,action) {
            state.sidebarState = state.sidebarState  ? false : true;
        },
        sideBarOn(state,action){
            state.sidebarState=true
        },
        sideBarOff(state,action){
            state.sidebarState=false
        }

    },
});

export const {uiActions,toggleSideBar,sideBarOn,sideBarOff} = uiSlice.actions;
export default uiSlice.reducer;
import React from 'react'
import styled from 'styled-components'
import {ArrowDropDown} from '@styled-icons/material/ArrowDropDown'
import {ArrowDropUp} from '@styled-icons/material/ArrowDropUp'
import { useState } from 'react'
import { Table } from '../../reusable/Table/Table'
import {b} from "../../styles/variables";
import { useSelector } from 'react-redux';
import { CSVLink } from "react-csv";
import {Cross as Cancel } from '@styled-icons/entypo/Cross'
import { cancelItem } from '../../redux/feature/orderSlice'
import { useDispatch } from 'react-redux'
import Loader from '../Loader'
import { ROLES } from '../../App'

const headers = [
  { label: "Sr. No.", key: "Srno" },
  { label: "User", key: "Username" },
  { label: "Food Item", key: "FoodName" },
  { label: "Qty", key: "Qty" },
  { label: "Amount", key: "Amount" },
  { label: "Vendor", key: "Vendor" }
  
];


function VendorOrders({vendorName,vendorOrders,orderType}) {
const allOrders=useSelector((state)=>state.order)
const liveOrders=allOrders.liveOrders
const auth = useSelector(state=>state.auth)  
const date=new Date().toLocaleDateString()
const dispatch=useDispatch()

//console.log(date)
let masterData = liveOrders[orderType.toUpperCase()]
//console.log(liveOrders[orderType.toUpperCase()][0])

let users = vendorOrders.map((item)=>{
  //console.log(masterData)


let data =masterData.map((order)=>{
  if (item.orderlist_id===order.orderlist_id){
    return order.user.user_name
    }

})
var filtered = data.flat().filter(function(x) {
  return x !== undefined;
});
return filtered.toString()})

let Total = vendorOrders.reduce((acc,next)=>{
  acc = parseInt(acc) + parseInt(next.food_prices)
return acc
},0)
//console.log(Total)
let downloadData=vendorOrders.map((orderlist,index)=>{
  let data ={}
 
 return {...data,Srno:index+1,Username:users[index],FoodName:orderlist.food.food_name,Qty:orderlist.quantity,Amount:orderlist.food_prices,Vendor:vendorName}

})
//console.log(downloadData)
const handleCancelItem = (item_id,food_name) => {
//console.log(item_id)
  //   if (window.confirm(`Remove ${food_name} from My Orders`))
  // {  
    dispatch(cancelItem([item_id]))
      
  // }
  //navigate(0) 
  }

console.log(vendorOrders)
const csvreport = {
  data: downloadData,
  headers: headers,
  filename: `${orderType}_${date}_${vendorName}.csv`
};
return(
    <DataWrapper >     
        <DataCard >        
         {/* <FlexWrapper1><h6>. </h6></FlexWrapper1> */}
          <FlexWrapper>
            <h6>{vendorName}</h6>
          {/* <ArrowIconContainer>
           {!dataVisible?<ArrowDown onClick={()=>setDataVisible(true)}/>:<ArrowUp onClick={()=>setDataVisible(false)}/>}
           </ArrowIconContainer> */}
        </FlexWrapper>
        {/* <FlexWrapper><h6>{vendorOrders?.user?.fs_id }</h6></FlexWrapper> */}
        </DataCard> 
        <OrderList >
           <Table>
            <Table.Head>
                <Table.TR>
            <Table.TH>
                Sr. No.
            </Table.TH>
            <Table.TH>
               User
            </Table.TH>
            <Table.TH>
                Food Item
            </Table.TH>
            <Table.TH>
                Qty
            </Table.TH>
            <Table.TH>
               Amount
            </Table.TH>
            {/* <Table.TH>
               Vendor
            </Table.TH> */}
           {auth?.creds?.roleslist?.includes(ROLES['Admin']) &&
            <Table.TH>
               
            </Table.TH>}
            </Table.TR>
            </Table.Head>
            <Table.Body>
           {allOrders.isLoading ? <Loader/> :

<>{vendorOrders?.map((orderlist,index)=>{                
              return  (<React.Fragment key={index}>
               <Table.TR>

                  <Table.TD>
                    {index+1}
                  </Table.TD>
                  <Table.TD>
                  {users[index]}
                  </Table.TD>
                  <Table.TD>
                    {orderlist.food.food_name}
                  </Table.TD>

                  <Table.TD>
                  {orderlist.quantity}
                  </Table.TD>
                  <Table.TD>
                  {orderlist.food.food_price * orderlist.quantity}
                  </Table.TD>
                  {/* <Table.TD>
                  {vendorName}
                  </Table.TD> */}
                  {auth?.creds?.roleslist?.includes(ROLES['Admin']) &&
                  <Table.TD>
                  <CancelIcon size= {22} onClick={()=>handleCancelItem(orderlist.id,orderlist?.food?.food_name)}/>
                  </Table.TD>}
                  
                  </Table.TR>
                </React.Fragment>)
            })}
            <Table.TR> 
                <Table.TD></Table.TD>
                <Table.TD></Table.TD>
                <Table.TD></Table.TD>                
                <Table.TD><b>Total</b></Table.TD>
                <Table.TD><b>{Total}</b></Table.TD>
                {auth?.creds?.roleslist?.includes(ROLES['Admin']) &&
                <Table.TD></Table.TD>}
            </Table.TR></>
}
        </Table.Body>

           </Table> 
        </OrderList>       
         <DownloadButtonWrapper>
           {/* <DownloadButton>Download</DownloadButton>  */}
           <DownloadLink {...csvreport}>Download</DownloadLink>
         </DownloadButtonWrapper>     
        </DataWrapper>
  )
}

export default VendorOrders;

const DataCard = styled.div`
display:flex;
width: 100%;
padding: 1rem 0;
color: #4f4c4c;
font-weight: 100;
&:hover{
  background-color:#f9f6ee;

}

`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex:1;
  justify-content:left;
  h6{
    font-weight: 600;
  } 
  @media ${b.mobile}{  
  h6{
    font-size: 1.3rem;
  }   
}`
const FlexWrapper1 = styled.div`
  display: flex;
  flex-direction: row;
  flex:0.3;
  justify-content:left;
  @media ${b.mobile}{  
  h6{
    font-size: 1.3rem;
  }   
}
`

const OrderList= styled.div`
display: flex;
flex-direction: row;
width: 100%;
justify-content: center;
/* display: ${({dataOn})=>dataOn? "flex" : "none"}; */
`;

const DataWrapper = styled.div`
display: flex;
flex-direction: column;
`
const ArrowIconContainer = styled.div`
margin-left : 2%;
width:20px;
height: auto;
`


const DownloadButtonWrapper = styled.div`
width: 100%;
display: flex;
justify-content: right;
padding: 1rem 2rem;
`
const DownloadLink = styled(CSVLink)`
font-size: 1.3rem;
border-radius: 2.5rem;
border: 1px solid #F7707D;
padding: 0.5rem 2rem;
&:hover{
background-image:linear-gradient(90deg, #F7707D 0%, #F29173 100%);
color:white;
border: none;
cursor: pointer;
}
@media ${b.mobile}{
font-size: 1rem;
padding: 0.4rem 1.5rem;
}
`
const CancelIcon = styled(Cancel)`
color: #F7707D ;


&:hover{
  color: #f72d42 ;
  
  /* opacity: 0.8; */
  cursor: pointer;
}
`
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {            
      creds: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {},
      isloading:false,
      isAuthenticated:JSON.parse(localStorage.getItem("user"))?.token ? true : false ,
      error:null      
    },
    reducers: {
      getUserCreds(state){         
        state.isloading=true           
      },      

      loginSuccess: (state, action) => {

        state.creds = action.payload;        
        state.isAuthenticated=true;
        state.isloading=false
        localStorage.setItem("user", JSON.stringify(action.payload))
        state.error=null
      },
      
      loginFail:(state,action)=> {
        state.isloading=false
        state.error=action.payload;
        // console.log(state.error)
      },
      logOutUser:(state)=>{
        state.creds ={};        
        state.isAuthenticated=false;
        localStorage.clear();
        sessionStorage.clear();
      },
      logoutSuccess: () =>  {
        
      },
      
      // authenticateUser: (state) => {
      //   state.creds = JSON.parse(localStorage.getItem("user"))
      //   state.creds ? state.isAuthenticated= true : state.isAuthenticated= false
      // },
      getUserInfo:(state)=>{
      state.isloading=true
      },
      updateUserInfo:(state,action)=>{
        //console.log(action.payload)     
        state.creds={...state.creds, roleslist:action.payload.roleslist,userlocation:action.payload.user_location,usercredit:action.payload.user_credit}
        localStorage.setItem("user", JSON.stringify(state.creds))
        state.isloading=false
      },

      updateUserLoc:(state)=>{
        
      }

    },
    
  });
  
export default authSlice.reducer;
export const { getUserCreds,logOutUser, loginFail,getMsData,authenticateUser,loginSuccess,getUserInfo,updateUserInfo,updateUserLoc} = authSlice.actions 
import React, { useEffect,useState } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Layout from './components/Layout'
import LoginPage from './Pages/loginPage'
import Unauthorized from './Pages/unauthorized';
import HomePage from './Pages/homePage';
import RequireAuth from './components/RequireAuth';
import AllOrders from './Pages/allorders';
import Finance from './Pages/finance'
import { GlobalStyles } from './styles/globalStyles';
import { ThemeProvider } from 'styled-components';
import {darkTheme,lightTheme} from './styles/theme';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import LoginLoad from './Pages/loginload';
import Cart from './components/Cart';
import Menu from './components/Menu';
import AllVendors from './Pages/allvendors'
import MenuPage from './Pages/menuPage';
import { authenticateUser, getUserInfo } from './redux/feature/authSlice';
import MyOrders from './components/MyOrders';
import MyLocation from './components/MyLocation/Index';
import ByUser from './components/ByUser';
import ByVendor from './components/ByVendor';
import { getTotals, updateCartCredit } from './redux/feature/cartSlice';
import {Toaster} from 'react-hot-toast';
import BillingFilters from './components/Filters';

export const ROLES = {
  'User': 1001,
  'Admin': 2001,
  'Finance': 3001,
  'AdminRead':5001,
}

function App() {
    const auth=useSelector((state) => state.auth)
    const cart = useSelector((state)=>state.cart)
    const dispatch=useDispatch()
    const navigate = useNavigate()
    
useEffect(() => {  
  if(auth.isAuthenticated)  {
      dispatch(getUserInfo())
      dispatch(getTotals(auth.creds.usercredit));
  }
      //dispatch(updateCartCredit(auth.creds.usercredit))
      
     },[]);

  const {theme} = useSelector((state) => state.ui);
  //console.log(theme)
  const currentTheme = theme === "light" ? lightTheme : darkTheme;
  return (
<ThemeProvider theme={currentTheme}>
<GlobalStyles/>
<Toaster />
<Routes>
  {/* UnProtected routes */}
      <Route path='/' element={<Layout/>}>
        <Route path="loading" element={<LoginLoad/>} />
        <Route path="login" element={<LoginPage/>} />              
        <Route path="unauthorized" element={<Unauthorized/>} />

  {/* Protected routes */}
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route path="/" element={<HomePage/>}/> 
            <Route path="mylocation" element={<MyLocation/>}/>        
            <Route path="vendors" element={<AllVendors/>}/>            
            <Route path="cart" element={<Cart/>} />
            <Route path="myorders" element={<MyOrders/>} />
            <Route path = "fooddata/" >
                <Route path = "foodbyvendor/:ext" element={<Menu url="foodbyvendor"/>}/>
                <Route path = "foodall" element={<Menu url="foodall"/>}/>
                <Route path = "food_source/:source" element={<Menu url="food_source"/>}/>
                <Route path = "food_avail/:avail" element={<Menu url="food_avail"/>}/>
            </Route>

        </Route>0
        
        <Route element={<RequireAuth allowedRoles={[ROLES.Admin,ROLES.AdminRead]} />}>
          <Route path="liveorders/" element={<AllOrders/>}>
            <Route path="byuser" element={<ByUser/>} />
            <Route path="byvendor" element={<ByVendor/>} />
          </Route>
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Finance]} />}>
          <Route path="analysis/">
          <Route path = "financebydate" element={<Finance/>} />
          <Route path = "filters" element={<BillingFilters/>} />
          </Route>
        </Route>

      </Route>
</Routes>
</ThemeProvider>   
  )
}
export default App


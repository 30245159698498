import axios from "axios";
import axiosRetry from 'axios-retry';

// export const redirectUri = "http://localhost:3000/login";
export const redirectUri = "https://foodapp.dev.afreespace.com/login";

const axiosInstance = axios.create({
  
  //baseURL: "http://localhost:3001/",
   baseURL: "https://foodappws.dev.afreespace.com/",
  headers: {
  'Content-Type': 'application/json',   
 },
})

axiosInstance.interceptors.request.use(function (config) {
  let token = JSON.parse(localStorage.getItem("user"))?.token
  config.headers.accessToken =  token ? token : '';
  return config;
});





// axiosRetry(axiosAuthInstance, { retries: 3 })

export function authApi(useremail,username) {
   //console.log(useremail)
    return axiosInstance.post('userdata/login',  {
      username: username,
      useremail: useremail
    }
    )
  }
  

  export function vendorsApi() { 
    console.log(JSON.parse(localStorage.getItem("user"))?.token)     
      return axiosInstance.get(`vendordata/vendorall`)
    }

  export function vendorsApiLocation(location) {      
        return axiosInstance.get(`vendordata/vendor/${location}`)
      }

  
  
  export function menuApi(params) {
    //console.log(JSON.parse(localStorage.getItem("user"))?.token)
    //console.log(params)
   return axiosInstance.get(`fooddata/${params}`)
}

export function searchFoodApi(params) { 
 return axiosInstance.get(`fooddata/foodsearch/${params}`)
}


export function placeOrderApi(orderList) {
  //console.log(orderList)
  return axiosInstance.put(`orderdata/order_insert`,{
    data: {...orderList}       
  })
}

export function getMyOrderApi(){
  return axiosInstance.get('orderdata/my_orders')
}
export function getLiveOrdersApi(){
  return axiosInstance.post('orderdata/allorders',{
    basedon:"user"
  })
}

export function mapBulkTokenApi(filter_type){
  return axiosInstance.post('orderdata/map_bulkorder',{
    filter_type:filter_type,
    basedon:"user"
  })
}


export function userInfoApi(){  
  return axiosInstance.get('userdata/basicinfo')
}

export function updateLocationApi(location){
  //console.log(axiosInstance.accessToken)  
  return axiosInstance.put('userdata/userlocation', {
    userlocation:location
  })
}

export function updateCreditApi(credit){
    
  return axiosInstance.put('userdata/usercredit', {
    usercredit:credit
  })
}

export function cancelItemApi(item_id){
    
  return axiosInstance.post('orderdata/order_cancel', {
    cancel_id:item_id
  })
}

export function cancelOrderApi(orderId){
  //console.log(orderId)    
  return axiosInstance.post('orderdata/entire_order_cancel', {
    cancel_id:orderId
  })
}

export function getBulkTokenApi(){    
  return axiosInstance.get('orderdata/bulktokens')
}
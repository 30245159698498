import React from 'react'
import FinanceByDate from '../components/Finance'


function Finance() {
  return (
    <FinanceByDate/>
  )
}

export default Finance


export const v = {
    headerHeight: "65px",
    smSpacing: `8px`,
    mdSpacing: `16px`,
    lgSpacing: `32px`,
    xlSpacing: `48px`,
    xxlSpacing: `64px`,
    borderRadius: `6px`,
    cardBoxShadow: `0 1px 1px rgba(0,0,0,0.15), 
    0 2px 2px rgba(0,0,0,0.15), 
    0 4px 4px rgba(0,0,0,0.15), 
    0 6px 6px rgba(0,0,0,0.15)`,
};

export const s = {
    // 1460px = 91.25em
    xxl: "91.25rem",
    // 1200px = 75em
    xl: "75rem",
    // 1024px = 64em
    lg: "64rem",
    // 768px = 48em
    md: "48rem",
    // 600px = 37.5em
    sm: "37.5rem",
    //480px = 30em
    xsm: "30rem"
    
};

// BREAKPOINTS
export const b = { 
    rare:"(max-width:22.7rem)",   
    mobile:"(max-width : 30rem)",
    tablet:"(max-width : 48rem) and (min-width : 30.1rem)",
    desktop:"(min-width : 48.1rem)"
};
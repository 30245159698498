import { put,fork,call, takeEvery, takeLatest } from "redux-saga/effects";
import { vendorsApi,vendorsApiLocation} from "../api/allAxios";
import {getVendors,setVendors} from '../redux/feature/vendorSlice'
import { logOutUser } from "../redux/feature/authSlice";
import {clearCart} from '../redux/feature/cartSlice';
import {clearMenu} from '../redux/feature/menuSlice';
import {clearVendorList} from '../redux/feature/vendorSlice';
import {clearOrderData} from '../redux/feature/orderSlice';
import { toast } from "react-hot-toast";

function* getVendorsAsync(action) {
    try {
     //console.log(action) 
     const location=action.payload       
     const response =yield call(vendorsApiLocation,location) 
     const { data } = response 
     //console.log(data)
      yield put(setVendors({ ...data }));      
    } catch (error) {
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }
     //console.log(error.response.status);
    }
  }

 

function* watchGetVendors() {    
    yield takeLatest(getVendors.type, getVendorsAsync);
    //console.log("Inside GetVendors")
  }

  export const vendorSagas = [fork(watchGetVendors)]
import { combineReducers } from "@reduxjs/toolkit";
import auth from './authSlice';
import ui from './uiSlice';
import vendors from './vendorSlice'
import menu from './menuSlice'
import cart from './cartSlice'
import order from './orderSlice'
import billing from './billingSlice'
 const rootReducer = combineReducers({
  auth,
  ui,
  vendors,
  menu,
  cart,
  order,
  billing
})

export default rootReducer;
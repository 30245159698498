import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";



const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  userCredits: JSON.parse(localStorage.getItem("user"))?.usercredit,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    
    addToCart(state, action) {
      
      const ordertype = action.payload[1]    
      const existingIndex = state.cartItems.findIndex(
        (item) => item.food_id === action.payload[0].food_id
      );
      if (existingIndex >= 0) {
      
        const newQuantity = [...state.cartItems[existingIndex].cartQuantity]
        //console.log(newQuantity)
        if (action.payload.length>1){
          ordertype==="B" ? ( state.cartItems[existingIndex].availabilities.includes(1) ? newQuantity.splice(0,1,newQuantity[0]+1): toast.error("This Item is Unavailable for Breakfast")):
          ordertype==="L" ? ( state.cartItems[existingIndex].availabilities.includes(2) ? newQuantity.splice(1,1,newQuantity[1]+1) : toast.error("This Item is Unavailable for Lunch")):
          ( state.cartItems[existingIndex].availabilities.includes(3) ? newQuantity.splice(2,1,newQuantity[2]+1) : toast.error("This Item is Unavailable for Snacks")) 
        }
        
        //console.log(newQuantity)
        let totalNos = newQuantity.reduce((partialSum, a) => partialSum + a, 0)
        //console.log(totalNos )
         if (state.userCredits -  state.cartItems[existingIndex].food_price >=0)     
        {      
        state.cartItems[existingIndex] = {
          ...state.cartItems[existingIndex],
          cartQuantity:newQuantity,
           itemTotal:totalNos *state.cartItems[existingIndex].food_price
        };
      }
      else{
        toast.error("Cannot add this item, Insufficient Credit Balance!",
        {
          duration:2500,
          position:"bottom-right",
          // style:{width:"400px"}
        }
        )
       
      }
        
      } else {
        //console.log("in else")
        //console.log(state.cartItems)
        let tempProductItem =  { ...action.payload[0], cartQuantity: [0,0,0],itemTotal:0 }  
        state.cartItems.push(tempProductItem)
      
      }
      
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    decreaseCart(state, action) {
        //console.log(action.payload)
        const ordertype = action.payload[1]
      const itemIndex = state.cartItems.findIndex(
        (item) => item.food_id === action.payload[0].food_id
      );

      let sum = state.cartItems[itemIndex].cartQuantity.reduce(function (x, y) {
        return x + y;
    }, 0)
      //console.log(sum)
      if (sum > 1) {
          ordertype==="B"  ? state.cartItems[itemIndex].cartQuantity.splice(0,1,state.cartItems[itemIndex].cartQuantity[0]-1) :
          ordertype==="L"  ? state.cartItems[itemIndex].cartQuantity.splice(1,1,state.cartItems[itemIndex].cartQuantity[1]-1) :
          state.cartItems[itemIndex].cartQuantity.splice(2,1,state.cartItems[itemIndex].cartQuantity[2]-1)
        
          let itemTotal = state.cartItems[itemIndex].cartQuantity.reduce((partialSum, a) => partialSum + a, 0)
          state.cartItems[itemIndex] = {
            ...state.cartItems[itemIndex]
             ,itemTotal:itemTotal*state.cartItems[itemIndex].food_price
          };

      } else if (sum=== 1) {
        const nextCartItems = state.cartItems.filter(
          (item) => item.food_id !== action.payload[0].food_id
        );
        state.cartItems = nextCartItems;       
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    removeFromCart(state, action) {
      state.cartItems.map((cartItem) => {
        if (cartItem.food_id === action.payload[0]) {
          const nextCartItems = state.cartItems.filter(
            (item) => item.food_id !== cartItem.food_id
          );
          state.cartItems = nextCartItems;
        }
        localStorage.removeItem("cartItems");
        return state;
      });
    },
    getTotals(state,action) {
      //console.log(action.payload)
     let result = state.cartItems.map(item=>{
const tempquantity = item.cartQuantity.reduce((partialSum, a) => partialSum + a, 0)
return tempquantity
      })
      //console.log(result)
      state.cartTotalQuantity=result.reduce((partialSum, a) => partialSum + a, 0)

      let totalAmntArray = state.cartItems.map(item=>{
        return item.itemTotal
      })

      state.cartTotalAmount=totalAmntArray.reduce((partialSum, a) => partialSum + a, 0)
      state.userCredits=action.payload-state.cartTotalAmount
// if (state.userCredits<=0){
//   alert("You have exeeded the maximum puchase amount, Please remove some item from cart")
// }

   
    },
    clearCart(state) {
      state.cartItems = [];
      localStorage.removeItem("cartItems");
    
    },
    updateCartCredit(state,action) {
      state.userCredits=action.payload-state.cartTotalAmount
      if (state.userCredits<=0){
        alert("You have exeeded the maximum puchase amount, Please remove some item from cart")
      }   
    },
  
  },
});

export const { addToCart, decreaseCart, removeFromCart, getTotals, clearCart,updateCartCredit } =
  cartSlice.actions;

export default cartSlice.reducer;
import React from 'react'
import styled from 'styled-components';
import { useMediaQuery } from '../../hooks/mediaquery';
import {b} from "../../styles/variables"
import { useDispatch,useSelector } from 'react-redux';
import { getVendors } from '../../redux/feature/vendorSlice';
import { useEffect } from 'react';
import { Container ,NavLink} from '../../styles/globalStyles';
import { Button,FlxCenter } from '../../styles/globalStyles';
import { useState } from 'react';
import VendorCard from '../VendorCard';

function Vendors() {

  const auth=useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const {vendorList} = useSelector((state)=>state.vendors)
    const vendors = Object.values(vendorList)
    const location = auth.creds.userlocation
    
    // let isPageWide = useMediaQuery(b.mobile)
    // isPageWide && console.log("Viewing in mobile")
    useEffect(() => {
      
      dispatch(getVendors(location))
      
    }, [location]);
  
  return (   
  <MainContainer>
 
  <CardsContainer>
  {vendors.map(vendor => {
          return( <React.Fragment key={vendor.vendor_id} >
            <VendorCard vendor={vendor}/>
          </React.Fragment>
       ) })}
    
  </CardsContainer>
  
  </MainContainer>
    )
  
}

export default Vendors

const MainContainer = styled.div`
display: flex;
height:100%;
width:100%;
justify-content: center;

`

// const HeaderContainer = styled.nav`
// display: flex;
// flex-direction: row;
// height:70px;
// position:sticky;
// background: white;
// border-bottom: 2px solid ${({theme})=>theme.gray};
// justify-content:space-between;
// padding: 0 10px;



// @media ${b.mobile}{
//   flex-direction:column;
//   font-size:1.6rem;
//   left:0;
//   height: auto;
// }

// `

const CardsContainer = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
align-items: center;
overflow-y: auto;
overflow-x: hidden;
background-color: white;
padding-bottom: 6%;

@media ${b.mobile}{
  /* display: flex;
  flex-wrap: nowrap;
  flex-direction: column; */

}

`

const TitleContainer = styled.div`
${FlxCenter}
`

const Title = styled.h1`
  color:${({theme})=>theme.text1}
`;

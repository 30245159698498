import React, { useEffect, useMemo } from 'react'
import { useMsal } from "@azure/msal-react";
import { useDispatch } from 'react-redux'
import { getUserCreds} from '../redux/feature/authSlice'
import { useNavigate} from "react-router-dom"
import { login } from '../redux/feature/authSlice'
import Loader from '../components/Loader';
function LoginLoad() {
    // const {accounts} = useMsal()
    // const dispatch=useDispatch()
    // const navigate = useNavigate()



// useEffect(() => {
//     //dispatch(login())
//   //  console.log(accounts)
//     console.log("i am on loginload")
    
//     dispatch(getUserCreds({...accounts[0]}))
//     navigate('/', { replace: true })
    
   
    
// }, [accounts[0],dispatch,navigate,]);


  return (
    <Loader/>
  )
}

export default LoginLoad;
import { put,fork,call, takeEvery, takeLatest } from "redux-saga/effects";
import {getMenu,searchItem,setMenu} from '../redux/feature/menuSlice'
import {menuApi,searchFoodApi} from '../api/allAxios';
import {clearCart} from '../redux/feature/cartSlice';
import {clearMenu} from '../redux/feature/menuSlice';
import {clearVendorList} from '../redux/feature/vendorSlice';
import {clearOrderData} from '../redux/feature/orderSlice';
import { logOutUser } from "../redux/feature/authSlice";
import { toast } from "react-hot-toast";

function* getMenuAsync(action) {
    try {     
     const params = action.payload
     //console.log(params)     
     const response =  yield call(menuApi,params)
     const { data } = response 
     //console.log(data)
      yield put(setMenu({...data}));      
    } catch (error) {
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }

    }
  }

  function* searchItemAsync(action) {
    try {     
     const params = action.payload
     //console.log(params)     
     const response =  yield call(searchFoodApi,params)
     const { data } = response 
     //console.log(data)
      yield put(setMenu({...data}));      
    } catch (error) {
      const status = error.response.status
      if (status===401){
      toast.error("Your Session has expired, Please login again.")
      yield put(logOutUser())
      yield put(clearCart())
      yield put(clearMenu())
      yield put(clearVendorList())
      yield put(clearOrderData())
      }
    }
  }

  function* watchGetMenu() {
    //console.log("Inside Menu Saga")
    yield takeLatest(getMenu.type, getMenuAsync);
  }

  function* watchSearchFood() {   
    yield takeEvery(searchItem.type, searchItemAsync);
  }


  export const menuSagas = [fork(watchGetMenu),fork(watchSearchFood)]
import React from 'react'
import {Button,Card} from 'freespace-react-components'
import styled from 'styled-components'
import LiveOrders from '../components/ByUser'
import { useDispatch } from 'react-redux'
import { getLiveOrders,getBulkToken } from '../redux/feature/orderSlice'
import { useEffect } from 'react'
import { Outlet } from "react-router-dom"
import { useSelector } from 'react-redux'

const AllOrders = () => {
  const dispatch = useDispatch()
  useEffect(() => {       
    dispatch(getLiveOrders())
    dispatch(getBulkToken())  
         },[]); 

    return (                 
        <Outlet/>
        )}

export default AllOrders
import { createGlobalStyle } from "styled-components";
import { css } from "styled-components";
import {b} from "./variables"
import styled from "styled-components";
import { Link } from "react-router-dom";

export const GlobalStyles = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%;

    @media only screen and (max-width: 1200px){
        font-size: 58%;
    }
    @media only screen and (min-width: 1980px){
        font-size: 70%;
    }
}
body{
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    line-height: 1.6;
    font-size: 1.6rem;
    background: #F9F9F9;
    color: #333;

}
`;

export const MobileHide = css`
@media ${b.mobile} {
  display: none;
}`;

export const TabletHide = css`
@media ${b.tablet} {
  display: none;
}`;

export const FlxCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AbsCenter = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  `;

  export const Button= styled.button`
  color: ${({fc})=>fc?({fc})=>fc:"white"};
  background: ${({bg})=>bg?({bg})=>bg:"orange"};
  padding: ${({ptb})=>ptb?({ptb})=>ptb: 0} ${({prl})=>prl?({prl})=>prl: 0};
  font-size: ${({fs})=>fs?({fs})=>fs: "1.2rem"};
  width: ${({bw})=>bw?({bw})=>bw: "50%"};
  border-radius: ${(br)=>br?(br)=>br:"10px"}; 
  height: ${({bh})=>bh?({bh})=>bh: "38px"};
  font-weight: ${({fw})=>fw?({fw})=>fw: 400};
  border: ${({bb})=>bb?({bb})=>bb: "none"};
  box-shadow: none;
  cursor: pointer;
&:hover{
  color: ${({hfc})=>hfc?({hfc})=>hfc:"white"};
  opacity:0.8;
  
}

 
&:active{
    transform: translateY(.5rem);
}
`

 export const EmptyContainer=styled.div`
 width: ${({w})=>w};
 height: ${({h})=>h};
 `

export const Container = styled.div`
margin: 0 auto;
padding: 0 1rem;


@media  ${b.mobile}{
    padding:0 0.3rem;
}
@media  ${b.tablet} {
    padding: 0 0.6rem;
}
`

export const IconContainer = styled.div`

`
export const MyNavLink=styled(Link)`
color:${({fc})=>fc?({fc})=>fc:"white"};
font-size: ${({fs})=>fs?({fs})=>fs: "1.2rem"}; 
text-decoration: none;
 padding-left: ${({pl})=>pl?({pl})=>pl: 0};
 padding-right: ${({pr})=>pr?({pr})=>pr: 0};
 padding-top: ${({pt})=>pt?({pt})=>pt: 0};
 padding-bottom: ${({pb})=>pb?({pb})=>pb: "0rem"};
 cursor: pointer;
&:hover {
  color: ${({hfc})=>hfc?({hfc})=>hfc:"#38d68b"};
  opacity:0.8;
} 
&:focus{
    color:  ${({afc})=>afc?({afc})=>afc:"white"};
};
&:active{
    
    color:${({aac})=>aac?({aac})=>aac:"white"};
};
@media ${b.mobile} {
        display: ${({d})=>d?({d})=>d : "block"};
}

`

export const TextContainer = styled.div`
display:flex;

flex-direction: column;
font-size: ${({fs})=>fs?({fs})=>fs: "1.2rem"} ;
font-weight:${({fw})=>fw?({fw})=>fw : "400"};
color:${({fc})=>fc?({fc})=>fc: "black"};
padding-left:${({pl})=>pl?({pl})=>pl: 0} ;
padding-right:${({pr})=>pr?({pr})=>pr: 0};
padding-top:${({pt})=>pt?({pt})=>pt: 0} ;
padding-bottom:${({pb})=>pb?({pb})=>pb: 0} ;
justify-content:${({jc})=>jc?({jc})=>jc: "left"};
align-items:${({ai})=>ai?({ai})=>ai: "top"};
&:hover{
color:${({hfc,fc})=>hfc?({hfc})=>hfc: fc};
;

}
`

export const FlxColumn = css`
  display: flex;
  flex-direction: column;

`




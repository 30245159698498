import styled from 'styled-components';

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const THead = styled.thead`
 font-size: 1.2rem;
 font-weight: 500;
`;

export const TFoot = styled.tfoot`
  // custom css goes here
`;

export const TBody = styled.tbody`
 font-size: 1.2rem;
 font-weight: 500;
 width:100%;
`;

export const TR = styled.tr`
text-align: left;
background-color: #04AA6D;
color: white;
border: 1px solid #ddd;

 &:hover{
    
 }
`;

export const TH = styled.th`

text-align: center;
background-image:linear-gradient(90deg, #F7707D 0%, #F29173 100%);
color: white;
border: 1px solid #ddd;
  
`;

export const TD = styled.td` 
text-align: center;
background-color: whitesmoke;
color: Black;
border: 1px solid #ddd;
`;
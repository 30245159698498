export const lightTheme = {
    primaryorange: "#F7707D",
    secorange: "#F29173",
    text1: "#180270",//Dark Blue
    text2: "#CAC8C8",//Grey
    textInvert: "rgb(245, 245, 245)",
    textInvert2: "rgb(210, 210, 210)",
    primary1: "#F7707D",//Orange
    primary2: "#252c49",//Gray
    bg: "#252c49",//Blue
    bg2: "rgb(225, 225, 225)",
    bg3:"#38d68b", //Button Green
    bg4:"rgba(255, 255, 255, 0.8)",//nav bar white
    bg5:"#fffff",//Full white
    bg6:"#f5f5f5",//dull white
    hover:"rgba(231, 246, 239)",
    gray: "#CAC8C8",
    buttbg:"linear-gradient(90deg, #F7707D 0%, #F29173 100%)",

    breakpoint:{
        m: "(max-width : 48rem) and (min-width : 30.1rem)",
        s: "(max-width : 30rem)"
    }

};

export const darkTheme = {
    text: "rgb(245, 245, 245)",
    text2: "rgb(210, 210, 210)",
    textInvert: "rgb(20, 20, 20)",
    textInvert2: "rgb(55, 55, 55)",
    primary: "rgb(200, 117, 196)",
    primary2: "rgb(200, 117, 147)",
    bg: "rgb(3, 3, 3)",
    bg2: "rgb(30,30,30)",
};
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
const orderSlice = createSlice({
    name: "order",
    initialState: {            
      // liveOrders: localStorage.getItem("liveorders")
      // ? JSON.parse(localStorage.getItem("liveorders"))
      // : {},
      liveOrders:{},
      vendorBased:{BREAKFAST:[],LUNCH:[],SNACK:[]},
      myOrder: {BREAKFAST:[],LUNCH:[],SNACK:[]},
      bulk_token:{Breakfast:"",Lunch:"",Snack:""},     
      isLoading:false,      
      error:null      
    },
    reducers: {
      placeOrder(state){         
         state.isLoading=true          
      },
      orderPlaced(state,action){       
        const res=action.payload
        //console.log(res)
       
        res.forEach((msg)=>{
          toast.success(`Order for ${msg} has been Placed`,
        {
          position: 'bottom-right',
          duration: 3000,
          style:{
            width:"400px"
          }
      
      })
    })
      
        // alert("The Order for "+action.payload+"has been placed successfully")
        
       state.isLoading=false
        
        
     },   

      getMyOrder(state){
        state.isLoading=true
        state.myOrder={BREAKFAST:[],LUNCH:[],SNACK:[]}

      },
      setMyOrder(state,action){
        if (action.payload.length !== 0){
        const res= action.payload
        //console.log(res)
          res.map((item)=>{
            if (item.order_type==="BREAKFAST"){
              state.myOrder.BREAKFAST = (item)
            }
            if (item.order_type==="LUNCH"){
              state.myOrder.LUNCH = (item)
            }
            if (item.order_type==="SNACK"){
              state.myOrder.SNACK = (item)
            }
            return state
          })
          //console.log(state.myOrder)
        
        }
        else{
          state.myOrder= {BREAKFAST:[],LUNCH:[],SNACK:[]}
         
        }
        
        state.isLoading=false
        
      },
     
      getLiveOrders(state){
        //console.log("live order slice")
      },
      setLiveOrders(state,action){
      state.liveOrders=action.payload
      //localStorage.setItem("liveorders", JSON.stringify(state.liveOrders))
      state.isLoading=false
       //console.log(state.liveOrders)

       const getData= (rawData)=>{
        let temp=rawData.map((order)=>{          
          return order.orderlists
        })
        //console.log(temp)

       const res = temp.flat().reduce((acc,item)=>{
      let data={}
     if (acc.hasOwnProperty(item.food.vendor.vendor_name))
     {
      data= {...acc,[item.food.vendor.vendor_name]:[...acc[item.food.vendor.vendor_name],item]}
    }
    else {
      data = {...acc,[item.food.vendor.vendor_name]:[item]}
    }
     return data
    },{})
    
    return res
    }

state.vendorBased={
  BREAKFAST:getData(state.liveOrders.BREAKFAST),
  LUNCH:getData(state.liveOrders.LUNCH),
  SNACK:getData(state.liveOrders.SNACK)
  }
},
      mapBulkToken(state){
        state.isLoading=true
      },

      clearOrderData(state){
       state.myOrder= {BREAKFAST:{},LUNCH:{},SNACK:{}}
       state.liveOrders={}
      },

      updateCredit(state) {
        
      },
      cancelItem(state) {
        state.isLoading=true
      },
      cancelItemSuccess(state) {
        state.isLoading=false
        toast.success("Item removed from your order");
        
      },
      cancelItemFail(state) {
        toast.error("Couldn't cancel order after order is locked by Admin")
        state.isLoading=false
      },
      cancelOrder(state,action) {
        state.isLoading=true
      },
      cancelOrderSuccess(state) {
        state.isLoading=false
        toast.success("Entire Order Cancelled");     
        
      },
      cancelOrderFail(state) {
        toast.error("Couldn't cancel order after order is locked by Admin")
        state.isLoading=false
      },
     
      getBulkToken(state){
        state.isLoading=true
      },
      setBulkToken(state,action){
        state.bulk_token=action.payload
        state.isLoading=false
      }

    },
    
  });
  
export default orderSlice.reducer;
export const {placeOrder, getMyOrder, setMyOrder, getAllorders, getLiveOrders, orderPlaced,setLiveOrders,mapBulkToken,
              clearOrderData,updateCredit,cancelItem,getBulkToken, setBulkToken,cancelOrder,cancelItemSuccess,
              cancelItemFail,cancelOrderSuccess,cancelOrderFail} = orderSlice.actions 
import React from 'react'
import styled from 'styled-components'
import { FlxCenter, TextContainer } from '../../styles/globalStyles'
import { Button } from '../../styles/globalStyles'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { moveToCart,getTotals,addToCart} from '../../redux/feature/cartSlice'
import { useDispatch } from 'react-redux'
import {FoodTag} from '@styled-icons/boxicons-regular/FoodTag'
import {b} from '../../styles/variables'
export const AVAILS= {
  1:"Breakfast",
  2:"Lunch",
  3:"Snacks",
  4:"Dinner"
}
function MenuCard({menu,id,params}) {
   const [isHovering,setIsHovering]= useState(false)
   const navigate = useNavigate()
   const dispatch = useDispatch()
  //  console.log(params[0])
   //params[3] === "1" ? menu=menu[0] : menu = menu
   
   const handleAddToCart = (menu) => {
    dispatch(addToCart([menu]));    
    navigate("/cart")
  };
   
  return (

<CardContainer id={id} source={menu?.food_source} onMouseEnter={()=>setIsHovering(true)} onMouseLeave={()=>setIsHovering(false)}>
<DetailsContainer>
  <DetailWrapper>
<HeaderContainer>
    <TextContainer fc={"#180270"} fs={"1.9rem"} fw = {400} >{menu?.food_name}</TextContainer>
    {menu?.food_description && <><DescriptionContainer><TextContainer fc={"#301c80"} fs={"1rem"} hfc={"white"} fw = {200}><b></b> {menu?.food_description}<b></b></TextContainer></DescriptionContainer> </> }    
</HeaderContainer>
<VendorDetails><p><b>Resto Name: </b>  {menu?.vendor?.vendor_name}</p></VendorDetails>
<FoodSource>
<FoodTag size={20} color={menu?.food_source==="VEG" ? "green" : "#d40000"}/>
</FoodSource>
{menu.availabilities.length !==0 && <AvailabilityWrapper>
 <HWrapper> <h6><b>Available For: </b></h6></HWrapper>
<AvailabilityContainer>{menu.availabilities?.map((item,index)=>{
  
  return <p key={index}>{AVAILS[item]}</p>

})   
  }</AvailabilityContainer>

</AvailabilityWrapper>}

</DetailWrapper>
<PriceContainer>
<TextContainer fc={"#180270"} fs={"2rem"} fw = {600}>Price<div>{menu?.food_price}</div></TextContainer>
</PriceContainer>

</DetailsContainer>
<ButtonsContainer>
<AddButton source={menu?.food_source} hoverState={isHovering} onClick= {() => handleAddToCart(menu)}>Add To My Plate</AddButton>
</ButtonsContainer>
</CardContainer>

)}

export default MenuCard

export const CardContainer = styled.div`
font-family: nunito sans-serif;
display: flex;
flex-direction: row;
min-height:150px;
width: 100%;
background-color:white;
color: #180270;


&:hover{
    transform: translate(0, -2px);
    box-shadow: rgba(28,28,28,0.12) 0px 0.4rem 1.8rem;
}

@media ${b.mobile}{
  padding: 0 1rem;
}
`

export const DetailsContainer = styled.div`
display: flex;
flex: 5;
justify-content: space-between;
padding: 0 15px;
&:hover{
//transform: translate(0, -2px);

}

`
export const ButtonsContainer = styled.div`
display: flex;
flex: 2;
align-items: center;
margin-left: 3%;
`
export const HeaderContainer = styled.div`
display: flex;
justify-content: left;
flex-direction: column;
@media ${b.mobile}{
  font-size: 50%;
}
`
export const DescriptionContainer = styled.div`

`
export const PriceContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
div{
font-weight: 200;
font-size: 1.4rem;
}

`
export const AddButton = styled.button`
//background-image: ${({source,hoverState})=>source==="VEG" & hoverState ?`linear-gradient(90deg, #F7707D 0%, #F29173 100%)`: source==="NON-VEG" & hoverState ? `linear-gradient(90deg, #38d68b 0%, #00a154 100%)`:`linear-gradient(90deg, #F7707D 0%, #F29173 100%)`};
background:linear-gradient(90deg, #F7707D 0%, #F29173 100%);
border-radius: 2.2rem;
padding: 10px 20px;
border: none;
color: white;
font-weight: 500;
font-size:1.3rem;
&:hover { 
  cursor: pointer;

}
@media ${b.mobile}{
  border-radius:1.5rem;
  font-size:80%;
}

`
export const DetailWrapper=styled.div`
display: flex;
flex-direction: column;
justify-content: space-evenly;
padding: 1rem 0;
height: 100%;
width: 70%;
`;
export const VendorDetails = styled.div`
display: flex;
flex-direction: row;
p{
  font-size: 1.2rem;  
}
@media ${b.mobile}{
 flex-direction: column;
}
`;
export const AvailabilityWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: left;
width: 100%;
h6{
  font-size: 1rem;
}
@media ${b.mobile}{
flex-direction: column;
align-items: top;

}

`
export const HWrapper= styled.div`
display: flex;
width: 30%;
justify-content: left;
@media ${b.mobile}{
width: 100%;
}
`

export const FoodSource = styled.div`

`;
export const AvailabilityContainer=styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: left;
font-size: 1.2rem;
font-weight: 100;
p{
  padding: 0 0.3rem;
}

@media ${b.mobile}{
  font-weight: 200;
  font-size:1.1rem;
}
`

export const OutputContainer=styled.div`
display: flex;
flex-direction: row;

`

import React, { useEffect } from 'react'
import styled from 'styled-components'
import logo from '../../assets/logo.png'
import { Power } from '@styled-icons/fluentui-system-filled/Power'
import { FlxCenter } from '../../styles/globalStyles'
import { Button, EmptyContainer } from '../../styles/globalStyles'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../MsClient/authConfig"
import { useDispatch } from 'react-redux'
import { b } from '../../styles/variables'
import { useNavigate } from 'react-router-dom'
import { getUserCreds, authenticateUser } from '../../redux/feature/authSlice'

function UserLogin() {
  const { instance, accounts } = useMsal();
  //console.log(accounts)
  const dispatch = useDispatch()
  const navigate = useNavigate()


  useEffect(() => {
    console.log("accounts: ", accounts);
    if (accounts[0]) {
      try {
        instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        }).then(result => {
          console.log("Login Result", result);
          const userdata = result.account;
          dispatch(getUserCreds(userdata));
          sessionStorage.clear();
          navigate('/', { replace: true });
        }).catch(error => {
          console.log('Token acquisition error:', error); // Log the error message
          console.error(error); // Log the error object for more details
        });
      } catch (error) {
        console.log('SSO redirect error:', error);
      }
    }
  }, [accounts]);



  return (
    <MainContainer>
      <LoginCardContainer>
        <LogoContainer>
          <Logo src={logo} />
          <p>The Space to Work Effectively</p>
        </LogoContainer>
        <BodyContainer>
          <Container1><GreenPowerIcon size={20} /><span><h2>You are not Logged Into the Food App</h2></span>
          </Container1>
          <EmptyContainer w="10px" h="13px"></EmptyContainer>
          <Container2>
            {/* <LoginButton onClick={()=>{
            instance.loginPopup({
              scopes: ["email"]
            }).then(result=>{
              console.log("Login Result", result)
              const userdata=result.account
              dispatch(getUserCreds(userdata))
              //dispatch(authenticateUser())
              navigate('/', { replace: true })
            })
            // instance.loginRedirect(loginRequest)
          }}>Login</LoginButton> */}
            <LoginButton onClick={() => instance.loginRedirect(loginRequest).catch(e => {
              console.log('SSO login error:', e);
            })}>Login</LoginButton>
          </Container2>

        </BodyContainer>

      </LoginCardContainer>
    </MainContainer>
  )
}

export default UserLogin;

const MainContainer = styled.div`
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background: #f5f5f5;

`

const LoginCardContainer = styled.div`
  display: flex;  
  padding: 20px 30px;
  background: white;
  min-width:360px;
  min-height: 350px;
  border-radius: 13px;
  z-index: 1000;  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  @media ${b.mobile}{
    min-height: 100%;  
   min-width:100%;
    border-radius: 0;
  }

`
const LogoContainer = styled.div`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 300;

    & p{
      margin: auto;      
      font-weight: 300;
      color: #444444;
    }

`
const Logo = styled.img`
    width: 210px;
    height: 54.4px;
    
`;
const BodyContainer = styled.div`
  flex-direction: column;
  padding: 15px 10px 10px 10px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #444444;
  

`

const GreenPowerIcon = styled(Power)`
color: ${({ theme }) => theme.bg3};
margin-right: 5px;

`
const Container1 = styled.div`
flex-direction: row;
${FlxCenter}
h2{
  color: gray;
  font-size:1rem;
}
;

& div{
  width: 10px;
  height: 10px;
}

`
const Container2 = styled.div`
${FlxCenter}
`
const LoginButton = styled.button`
background-image: linear-gradient(90deg, #F7707D 0%, #F29173 100%);
border-radius: 20px;
padding: 10px 20px;
min-width: 95%;
border: none;
color: white;
cursor: pointer;
font-weight: 600;
font-size: 1.5rem;

&:hover {
background-image: linear-gradient(90deg,#F29173 0%, #F7707D 100%);
  /* transform: translate(0, -2px); */
  color: white;
  /* opacity: 0.8; */
}

`
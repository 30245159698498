import React, { useState } from "react";
//import "./SearchBar.css";
import {Search as SearchIcon} from "@styled-icons/bootstrap/Search";
import {CloseOutline as CloseIcon} from "@styled-icons/evaicons-outline/CloseOutline";
import styled from 'styled-components'
import { useDispatch } from "react-redux";
import { searchItem } from "../../redux/feature/menuSlice";
import OutsideAlerter from "../../hooks/outsideClickAlerter";
import { useMediaQuery } from "../../hooks/mediaquery";
import {b} from '../../styles/variables'

function SearchBar({ placeholder }) {
  const [wordEntered, setWordEntered] = useState("");
  const dispatch = useDispatch()
  let isMobileView = useMediaQuery(b.mobile)

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    //console.log(searchWord)
    dispatch(searchItem(searchWord))
  };

  const clearInput = () => {    
    setWordEntered("");
  };

  return (
    <MainContainer>
  <IconContainer> <SearchIcon size={isMobileView ? 15: 30} /></IconContainer>

      <InputWrapper>
       <OutsideAlerter setWordEntered={setWordEntered}> <Input
          type="text"
          placeholder={placeholder}
          value={wordEntered}
          onChange={handleFilter}
        />
        </OutsideAlerter>
      
      </InputWrapper>
      
    </MainContainer>
  );
}

export default SearchBar;


const MainContainer = styled.div`
display: flex;
flex-direction: row;
`;

const InputWrapper = styled.div` 
  display: flex;
`;

const IconContainer =styled.div`
height: 30px;
width: 50px;
background-color: white;
display: grid;
place-items: center;
`
const Input = styled.input`

`
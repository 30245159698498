import React from 'react'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import { Button, TextContainer } from '../../styles/globalStyles';
import { useState,useEffect } from 'react';
import {Notifications} from '@styled-icons/material-outlined/Notifications'
import {Menu as BiMenu}  from '@styled-icons/entypo/Menu'
import {LogOut as LogoutIcon} from '@styled-icons/boxicons-regular/LogOut'
import { useMsal } from "@azure/msal-react";
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { logOutUser } from '../../redux/feature/authSlice';
import { clearCart } from '../../redux/feature/cartSlice';
import { clearMenu } from '../../redux/feature/menuSlice';
import { clearVendorList } from '../../redux/feature/vendorSlice';
import SearchBar from '../SearchBar/Index';
import { clearOrderData } from '../../redux/feature/orderSlice';
import { useSelector } from 'react-redux';
import {b} from '../../styles/variables'
import { useMediaQuery } from '../../hooks/mediaquery';
import {Menu } from '@styled-icons/boxicons-regular/Menu'
import { sideBarOff, sideBarOn } from '../../redux/feature/uiSlice';

function Navbar() {
  const { instance} = useMsal();
  const dispatch=useDispatch();
  const cart = useSelector((state)=>state.cart)
  const auth = useSelector((state)=>state.auth)
const location = useLocation()
const path = location.pathname.split("/")

useEffect(() => {    
  dispatch(sideBarOff())     
  },[location]);
//click is the initial state and setclick will be the update state
const [click, setClick] = useState(false);
const [iconHoverState, setIconHoverState] = useState(false)

//Create a function to handle the click state of the menu icon. 
//if the menu icon was the menu bar at the beginning when clicked it will have the close icon
const handleClick = () => setClick(!click);
const closeMenu=()=>{
    
    }

let isMobileView = useMediaQuery(b.mobile)
   
  return (
  <NavBody>{
    isMobileView ? <Hamburger size={40} color={'#131c51'} onClick={()=>{dispatch(sideBarOn())}}/> :
<Header>

<>{path[1]===""?<TextContainer fs={"2rem"} fc={"#131c51"} fw={"650"}>Home</TextContainer>:
   path[1]==="cart"?<TextContainer fs={"2rem"} fc={"#131c51"} fw={"650"}>My Cart</TextContainer>:
   path[1]==="liveorders"?<TextContainer fs={"2rem"} fc={"#131c51"} fw={"650"}>All Orders</TextContainer>:
   path[1]==="finance"?<TextContainer fs={"2rem"} fc={"#131c51"} fw={"650"}>Accounts</TextContainer>:
   path[1]==="myorders"?<TextContainer fs={"2rem"} fc={"#131c51"} fw={"650"}>My Orders</TextContainer>:
   path[1]==="vendors"?<TextContainer fs={"2rem"} fc={"#131c51"} fw={"650"}>Restaurants</TextContainer>:
   path[1]==="mylocation"?<TextContainer fs={"2rem"} fc={"#131c51"} fw={"650"}>My Location</TextContainer>:
   path[1]==="analysis"?<TextContainer fs={"2rem"} fc={"#131c51"} fw={"650"}>Accounts</TextContainer>:
   <TextContainer fs={"2rem"} fc={"#131c51"} fw={"650"}>Menu</TextContainer>}</>

</Header>

  }
<SearchContainer>
<>{path[1]==='fooddata' && <SearchWrapper> <SearchBar placeholder="Enter a Food Name..."/></SearchWrapper>}</>

<> <CreditsWrapper path={path[1]}>{!isMobileView && <p>Hi <b>{auth?.creds?.username}</b>,</p>} <p>Your credit balance:  <b>{cart.cartTotalQuantity !==0 ? cart?.userCredits : auth?.creds.usercredit}</b></p></CreditsWrapper></>


</SearchContainer>



<AvatarWrapper>
  <IconWrapper>
<LogoutIcon size={isMobileView ? 25 : 30} color={iconHoverState?"#131c51":"#FF8A59"} onClick={()=>{
            // instance.logoutPopup({
            //   scopes: ["email"]
            // }).then(result=>{
              dispatch(logOutUser())
              dispatch(clearCart())
              dispatch(clearMenu())
              dispatch(clearVendorList())
              dispatch(clearOrderData())
              //localStorage.clear()
            // })
          }} 
          onMouseEnter={()=>setIconHoverState(true)} onMouseLeave={()=>setIconHoverState(false)}/>
</IconWrapper>
</AvatarWrapper>
  </NavBody>
 
  )
}

export default Navbar

const NavBody = styled.div`
display: flex;
flex-direction: row;
width: 100%;
justify-content:space-between;
align-items:center;
padding: 1rem 2rem;
`

const Header = styled.div`
  @media ${b.mobile}{
   display: none;
}
`

const SearchContainer = styled.div`

display: flex;
flex-direction: row;
  @media ${b.mobile}{
   //display:flex;
   flex-direction: column;
}

`

const SearchWrapper = styled.div`

`

const AvatarWrapper = styled.div`

`

const IconWrapper = styled.div`
height: 100%;
width: auto;
&:hover{
  cursor: pointer;
}
`;
const CreditsWrapper= styled.div`
display: flex;
flex-direction: column;
  @media ${b.mobile}{
   padding-left:${({path})=>path==='fooddata' ? "3rem" : 0}
}


`;
const Hamburger = styled(Menu)`
&:hover{
  cursor: pointer;
}


`

